<template>

	<!-- for header part -->
	<header> 

		<div class="logosec"> 
			<div style="text-align: center;"> <img src="/assets/images/logo2.png" style="height: 60px;"> </div> 
			<i @click="toggle_menu()" 
				class="bi bi-grid-fill icn menuicn color-1 mt-3"
				id="menuicn"
				alt="menu-icon" style="font-size: 22px;"></i>
		</div> 





		

		<div class="searchbar"> 
		<router-link to="/view-products" class="btn btn-3" style="margin-right: 5px;"> <i class="bi bi-layers"></i> Products</router-link>
		<router-link to="/view-packages" class="btn btn-3" style="margin-right: 5px;"> <i class="bi bi-collection"></i> Packages</router-link>
		<router-link to="/view-locations" class="btn btn-3"> <i class="bi bi-truck"></i> Delivery locations</router-link>
		</div> 

	



		<div class="message"> 
			<div class="circle"></div> 
			<router-link class="color-1" to="/orders"><i class="bi bi-chat-dots mt-3" style="font-size: 22px;"></i> </router-link>

			<router-link to="/settings" class="dp" style="border: solid 2px lightgrey; background-image: url('/assets/images/logo2.png'); background-size: cover;"> 
			</router-link> 
		</div> 



	</header> 



  
</template>



<script>
	 export default{

    methods : {
        toggle_menu(){
    let menuicn = document.querySelector('.menuicn'); 
let nav = document.querySelector(".navcontainer"); 
nav.classList.toggle("navclose")
      }
    }
  
  }

</script>



