<template>
<Header />

<section>
	
	<div class="container" style="text-align:center; padding-top: 50px; padding-bottom: 50px;">


		<div class="row">

			<div class="col-md-4"></div>
			<div class="col-md-4">
			
			<div>



		<div class="animate__animated animate__pulse">
			<h1 class="color-1"> <i class="bi bi-truck"></i> </h1>
		</div>


		<div class="animate__animated animate__pulse">
			<h3> Track your order</h3>
		</div>


		<div>
			
			<p v-if="status=='received' "> <i class="bi bi-check color-1"></i> Your order has been received</p>
			<p v-if="status=='preparing'"> <i class="bi bi-check color-1"></i> Your order is being prepared</p>
			<p v-if="status=='delivering'"> <i class="bi bi-check color-1"></i> Our rider has taken your order and is coming your way</p>
			<p v-if="status=='arrived'"> <i class="bi bi-check color-1"></i> Rider has arrived</p>
			

		</div>


		<div class="animate__animated animate__pulse">
		<input type="text" v-model="order_number" class="form-control" placeholder="Enter order number">
		</div>


		<button class="btn btn-1 mt-2" @click="track_order()">Check</button>



				


			</div>

			</div>
			<div class="col-md-4"></div>
			
		</div>


		
	</div>


</section>




<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import 'animate.css';
	import Swal from 'sweetalert2'
	export default{
		name : 'index',
		components : {Header,Footer},
		data(){
			return{
				order_number : '',
				status : '',
				details : []
			}
		},

		methods : {


			async track_order(){
				if(!this.order_number){
					Swal.fire({
				  title: 'Error!',
				  text: 'Please enter your order number',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}
				const res = await axios.get(this.$store.state.end_point+'api/order-details/'+this.order_number).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})

				this.details = []


			
			res.forEach((data)=>{
				this.status = data.status
			})


			if(res.length==0){
					Swal.fire({
				  title: 'Error!',
				  text: 'Order does not exist',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}

			},
		}

	}

</script>