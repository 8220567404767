<template>


<TopBar />

	<div class="main-container">
	<Sidebar /> 

		<div class="main"> 
		

		<div class="body-card shadow p-2 rounded">

			<div class="row">
			<div class="col-md-4">
				<h4>Orders</h4>
				<h6>Total orders :  {{orders.length}} </h6>
			</div>

			<div class="col-md-4">
				<label>Filter:</label> 
				<select class="form-select" v-model="status" @change="filter_orders()">
					<option>all</option>
						<option>received</option>
						<option>preparing</option>
						<option>delivering</option>
						<option>arrived</option>
					</select>
			</div>


			<div class="col-md-4">
				<label>Search:</label>
				<input @input="search_order()" type="search" v-model="search_item" class="form-control" placeholder="Search by order number">
			</div>


				
			</div>



			<div class="table-responsive" style="min-height: 200px;">

				<table class="table">

					<thead>
						<tr>
							<th>Order#</th> <th> Total cost </th> <th>Delivery fee</th> <th>Amount to pay</th> <th>Delivery location</th> <th>Payment</th> <th>Status</th> <th>Action</th>
						</tr>
					</thead>

					<tbody>
					
					<tr v-for="o in orders">
						
						<td> {{o.order_number}} </td>
						<td> Ksh.{{o.total_cost}} </td>
						<td> Ksh.{{o.delivery_fee}} </td>
						<td> Ksh.{{o.amount_to_pay}} </td>
						<td> {{o.location_name}} </td>
						<td> <span :class="{ 'text-success' : o.payment=='completed', 'text-danger' : o.payment=='pending' }"> <i :class="{ 'bi bi-check' : o.payment=='completed', 'bi bi-x' : o.payment=='pending' }"></i> {{o.payment}}</span> </td>

						<td>
							{{o.status}}
						</td>


						<td>

							<div class="dropdown">
  <button class="btn btn-4 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Manage
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
   <li><router-link :to="{ name : 'view-order', params : { order_number : o.order_number } }" class="dropdown-item">View</router-link></li>
    <li><button @click="delete_order(o.order_number)" class="dropdown-item">Delete</button></li>
  </ul>
</div>
						</td>

					</tr>

					</tbody>
					
				</table>


				<p v-if="orders.length==0" class="text-danger">No orders found!</p>
				
			</div>
			
		</div>






		</div> 


		


	</div> 
	
</template>



<script>
	import Sidebar from './layouts/Sidebar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import Swal from 'sweetalert2'



	export default{
		name : 'dashboard',
		components : {Sidebar,TopBar},

		data(){
			return{
			orders : [],	
			search_item : '',
			status : ''
		}},

		methods : {

			async filter_orders(){
				const res = await axios.post(this.$store.state.end_point+'api/filter-orders',{
					status : this.status
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			
			this.orders = res

			},

			async search_order(){
				const res = await axios.post(this.$store.state.end_point+'api/search-order',{
					search_item : this.search_item
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			
			this.orders = res

			},

			async delete_order(order_number){
				const res = await axios.post(this.$store.state.end_point+'api/delete-order',{
					order_number : order_number
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.get_orders()

			},

			async get_orders(){
				const res = await axios.get(this.$store.state.end_point+'api/get-orders').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.orders = res

			}
			
		},

		created(){
		this.get_orders()	
		}
	}
</script>

