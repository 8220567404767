<template>
	
	<section>
		

		<div class="container mt-3">

			<div class="row">
				<div class="col-md-1"></div>
				<div class="col-md-5" style="background-image:url('/assets/images/eatclean-3-2.png'); background-size: cover; background-position: center; min-height: 300px;"></div>
				<div class="col-md-5" style="background-color: #f5f5f5; padding-top: 80px; padding-bottom: 100px;">

					<div>
						<img src="/assets/images/logo.png" class="logo">
					</div>

					<h6 class="color-3">Please sign-in to your account</h6>
					<h3>Eat Clean Achara</h3>

					<form @submit.prevent="login()">

						
						<div>
							<label>Username</label>
							<input type="text"  class="form-control" v-model="username" placeholder="Enter valid username">
						</div>

						<div class="mt-3">
							<label>Password</label>
							<input type="password"  class="form-control" v-model="password" placeholder="Enter valid password">
						</div>


						<div class="mt-3">
							<button class="btn btn-1 w-100" :disabled="disabled">{{text}}</button>
						</div>

					</form>

				</div>
				<div class="col-md-1"></div>


			</div>
			
		</div>

	</section>


	<Footer2 />

</template>



<script>
	import Footer2 from './layouts/Footer2'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name  : 'login',
		components : {Footer2},
		data(){
			return{
				username : '',
				password : '',
				text : 'Login',
				disabled : false
			}
		},
		methods : {

			async login(){

				if(!this.username){
					Swal.fire({
				  title: 'Error!',
				  text: 'Username  is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}

				if(!this.password){
					Swal.fire({
				  title: 'Error!',
				  text: 'Password is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}

				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.end_point+'api/admin-login',{
					username : this.username,
					password : this.password
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
				
				if(res.length>0){
					res.forEach((data)=>{
						localStorage.setItem('admin_logged_in',true)
						localStorage.setItem('username',data.username)
						localStorage.setItem('email',data.email)
						localStorage.setItem('phone',data.phone)
						localStorage.setItem('id',data.id)
					})
					this.$router.push('/dashboard')
				}else{

					Swal.fire({
				  title: 'Error!',
				  text: 'Wrong username or password!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})

				}
				this.text = 'Please wait...'
				this.disabled = true


			}

		}
	}

</script>