<template>
<Header />

<section>
	
	<div class="container" style="text-align:center; padding-top: 50px; padding-bottom: 50px;">

		<div class="animate__animated animate__pulse">
			<h3 class="color-3">CONTACT US</h3>
		</div>


		<div class="animate__animated animate__pulse">
			<h1> WE’D LOVE TO HEAR FROM YOU </h1>
		</div>


		<div class="animate__animated animate__pulse">
			<p>
				If you need any help, don’t hesitate to get in touch with us,<br/> send us an email or call us!
You will get your response as soon as possible.
			</p>
		</div>
		
	</div>


</section>





<section style="padding-bottom:100px;">
	

	<div class="container">

		<div class="row">

			<div class="col-md-4" style="text-align:center; border-right: solid 1px lightgrey;">
				<h3> <i class="bi bi-whatsapp color-1"></i> </h3>
				
				<h5><b>PHONE/WHATSAPP</b></h5>
				<div>
					Phone: (+254) 701943230
				</div>
				<div>
				WhatsApp: (+254) 701943230
				</div>

			</div>


			<div class="col-md-4" style="text-align:center; border-right: solid 1px lightgrey;">
				<h3> <i class="bi bi-geo-alt color-1"></i> </h3>
				
				<h5><b>ADDRESS</b></h5>
				<div>
				City Park Dr, Nairobi
				</div>
				<div>
					Kenya
				</div>
				

			</div>



			<div class="col-md-4" style="text-align:center; border-right: solid 1px lightgrey;">
				<h3> <i class="bi bi-envelope color-1"></i> </h3>
				
				<h5><b>EMAIL</b></h5>
				<div>
				info@eatcleanachara.com
				</div>
				<div>
					order@eatcleanachara.com
				</div>
				

			</div>





			
		</div>
		
	</div>


</section>





<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	export default{
		name : 'index',
		components : {Header,Footer}
	}

</script>