import { createStore } from 'vuex'

export default createStore({
  state: {
    end_point : 'https://server.eatcleanachara.co.ke/',
    admin_logged_in : localStorage.getItem('admin_logged_in'),
    username : localStorage.getItem('username'),
    email : localStorage.getItem('email'),
    phone : localStorage.getItem('phone'),
    id : localStorage.getItem('id'),
    is_package_selected : 'no',
    package_id : localStorage.getItem('package_id'),
    package_qty : localStorage.getItem('package_qty'),
    package_discount : localStorage.getItem('package_discount'),
    package_name : localStorage.getItem('package_name'),
    cart : [],
    total_qty : localStorage.getItem('total_qty'), //total calculate total qty on pages,
    total_qty_final : localStorage.getItem('total_qty_final'), //total calculate total qty on pages and prevent checking put when qty is more than package size
    total_cost : localStorage.getItem('total_cost'),
    final_total_cost : localStorage.getItem('final_total_cost'),
    delivery_fee : localStorage.getItem('delivery_fee'),
    amount_to_pay : localStorage.getItem('amount_to_pay'),
    rider_pin : localStorage.getItem('rider_pin'),
    rider_name : localStorage.getItem('rider_name')
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
