<template>


<TopBar />

	<div class="main-container">
	<Sidebar /> 

		<div class="main"> 
		

		<div class="body-card shadow p-2 rounded">

			<div class="row">
			<div class="col-md-6">
				<h4>New Delivery Location</h4>
			</div>


			<div class="col-md-6" style="text-align:right;">
				<router-link to="/view-locations" class="btn btn-1"> <i class="bi bi-grid"></i> View locations</router-link>
			</div>
				
			</div>



			<div>

				<form @submit.prevent="edit_location()" enctype="multipart/form-data">
					
					<div class="row">
						<div class="col-md-6 mt-2">
							<label>Location name</label>
							<input type="text" class="form-control" placeholder="Enter location name" v-model="name">
						</div>


						<div class="col-md-6 mt-2">
							<label>Price</label>
							<input type="number" min="1" class="form-control" placeholder="Enter number of items in the package" v-model="price">
						</div>

				


						<div class="col-md-12 mt-2">
							<button class="btn btn-1">Save changes</button>
						</div>

					</div>

				</form>
				
			</div>
			
		</div>


		</div> 


		


	</div> 
	
</template>



<script>
	import Sidebar from './layouts/Sidebar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import Swal from 'sweetalert2'



	export default{
		name : 'dashboard',
		components : {Sidebar,TopBar},

		data(){
			return{
			name : '',
			price : '',
			id : '',
			details : []	
		}},

		methods : {


			async location_details(){

				const res = await axios.get(this.$store.state.end_point+'api/location-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
				this.details = res 
				res.forEach((data)=>{
					this.name = data.name 
					this.price = data.price
				})

			},

			async edit_location(){

				if(!this.name){
					Swal.fire({
				  title: 'Error!',
				  text: 'Location name is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}


				if(!this.price){
					Swal.fire({
				  title: 'Error!',
				  text: 'Price is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}

				

				const res = await axios.post(this.$store.state.end_point+'api/edit-location',{
					name : this.name,
					price : this.price,
					id : this.id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
				
				if(res=='success'){
					Swal.fire({
				  title: 'Success',
				  text: 'Changes saved successfully',
				  icon: 'success',
				  confirmButtonText: 'Close'
				})
					
				}else{

					Swal.fire({
				  title: 'Error!',
				  text: res,
				  icon: 'error',
				  confirmButtonText: 'Close'
				})

				}


			}
			
		},

		created(){
			this.id  = this.$route.params.id 
			this.location_details()	
		}
	}
</script>

