<template>
	
	<section>
		

		<div class="container">

			<div class="row">
				<div class="col-md-4"></div>
				
				<div class="col-md-4" style="padding-top: 20px; padding-bottom: 20px; text-align: center; ">

					<div>
						<img src="/assets/images/logo.png" class="logo">
					</div>

					<h6 class="color-3">PIN</h6>



<div class="dialpad-container">
 
 <div style="text-align:center; min-height:40px;">
 	<h3 :class=" { 'animate__animated animate__shakeX' : show_error,'text-danger' : show_error } "> <label v-for="p in pin"> <i class="bi bi-dot"></i> </label> </h3>
 </div>

  <div class="row">
    <div class="col">
      <button class="dialpad-button" @click="appendNumber('1')">1</button>
      <button class="dialpad-button" @click="appendNumber('2')">2</button>
      <button class="dialpad-button" @click="appendNumber('3')">3</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button class="dialpad-button" @click="appendNumber('4')">4</button>
      <button class="dialpad-button" @click="appendNumber('5')">5</button>
      <button class="dialpad-button" @click="appendNumber('6')">6</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button class="dialpad-button" @click="appendNumber('7')">7</button>
      <button class="dialpad-button" @click="appendNumber('8')">8</button>
      <button class="dialpad-button" @click="appendNumber('9')">9</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button class="dialpad-button" @click="clearDisplay()">C</button>
      <button class="dialpad-button" @click="appendNumber('0')">0</button>
      <button class="dialpad-button" @click="backspace()">←</button>
    </div>
  </div>
</div>


				</div>
				<div class="col-md-4"></div>


			</div>
			
		</div>

	</section>


	<Footer2 />

</template>



<script>
	import Footer2 from './layouts/Footer2'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name  : 'login',
		components : {Footer2},
		data(){
			return{
				pin : '',
				show_error : false

			}
		},
		methods : {

			clearDisplay(){
				this.pin = ''
				this.show_error = false
			},

			backspace(){
				this.pin = this.pin.substring(0,this.pin.length-1)
				this.show_error = false
			},

			appendNumber(digit){

			if(this.pin.length<4){
			this.pin+=digit	
			}	

			if(this.pin.length==4){

			if(this.pin!='0000'){
				this.appendNumber1(this.pin)
			}

			}

			},

			async appendNumber1(pin){
					//try to login
					const res = await axios.post(this.$store.state.end_point+'api/rider-login',{
					pin : this.pin
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			
				if(res.length>0){

					res.forEach((data)=>{
						this.$store.state.rider_pin = data.pin
						this.$store.state.rider_name = data.first_name
					})

				this.$router.push('rider-dashboard')

			}else{
				this.show_error = true
				
			}

			}

		}
	}

</script>



<style scoped>
	

	/* Container styling */
.dialpad-container {
  width: 300px;
  margin: 0 auto;
}

/* Button styling */
.dialpad-button {
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
  margin: 5px;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dialpad-button:hover {
  background-color: #f0f0f0;
}

/* Input display styling */
.dialpad-display {
  width: 100%;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 20px;
}

/* Additional styling for the dialpad container */
.dialpad-container .row {
  display: flex;
  justify-content: center;
}

.dialpad-container .row .col {
  flex: 1;
  text-align: center;
}


</style>