<template>
<Header />

<section>
	
	<div class="container" style="text-align:center; padding-top: 50px; padding-bottom: 50px;">

		<div class="animate__animated animate__pulse">
			<h3 class="color-3">MENU</h3>
		</div>


		<div class="animate__animated animate__pulse">
			<h1> CHOOSE FROM A VARIETY OF MEALS </h1>
		</div>

		<div>
			<p>From breakfast to dinner, we got you covered.</p>
		</div>

		
	</div>


</section>





<section style="padding-bottom:100px;">
	

	<div class="container">

	
		<div class="row">
			<div class="col-md-3 mt-3" v-for="c in categories">
				<router-link class="btn product-category" :to=" { name : 'menu-items', params : { id : c.id } } " :style=" { backgroundImage : 'url('+this.$store.state.end_point+'assets/product-category-images/'+c.image+')' } ">
					{{c.name}}
				</router-link>
			</div>


		</div>
		
	</div>


</section>





<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import 'animate.css';
	export default{
		name : 'index',
		components : {Header,Footer},
		data(){
			return{
				categories : []
			}
		},
		methods : {

			async list_categories(){
				const res = await axios.get(this.$store.state.end_point+'api/get-categories').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				this.categories = res 
		
			}

		},
		created(){
			this.list_categories()
		}
	}

</script>