<template>


<TopBar />

	<div class="main-container">
	<Sidebar /> 

		<div class="main"> 
		

		<div class="body-card shadow p-2 rounded">

			<div class="row">
			<div class="col-md-6">
				<h4 class="text-danger">Orders#:{{order_number}}</h4>
			</div>


				
			</div>



			<div class="table-responsive">

				<table class="table table-hover table-striped">

					<thead>
						<tr> <th> Qty </th> <th> Item </th> <th>Price</th> <th> Sub total </th> </tr>
					</thead>

					<tbody>
						
						<tr v-for="i in items">
							<td> {{i.qty}}x </td>
							<td> {{i.name}} </td>
							<td> Ksh.{{i.price}} </td>
							<td> Ksh.{{i.price * i.qty}} </td>
						</tr>

					</tbody>
					
				</table>
				
			</div>


			<div class="row">

					<div class="col-md-3">
						<h5>Total cost : Ksh.{{total_cost}} </h5>
					</div>

					<div class="col-md-3">
					<h5>Delivery fee : Ksh.{{delivery_fee}} </h5>	
					</div>


					<div class="col-md-3">
						<h5>Amount to pay : Ksh.{{amount_to_pay}} </h5>
					</div>
					

				</div>





				<div class="row border-top">

					<div class="col-md-3 mt-2">
						<h5>Status : {{status}} </h5>
					</div>

					<div class="col-md-3 mt-2">
					<h5>Payment : <span :class=" { 'text-success' : payment=='completed', 'text-danger' : payment=='pending' } "> <i :class=" { 'bi bi-check' : payment=='completed', 'bi bi-x' : payment=='pending' } "></i> {{payment}}</span> </h5>	
					</div>


					<div class="col-md-6 mt-2">
						<h5>Delivery location : {{location}} <i class="bi bi-geo-alt color-1"></i> </h5>
					</div>
					

				</div>





				<div class="mt-3">
					<h4>Customer details</h4>
					<div class="table-responsive">
					<table class="table table-hover table-striped">
						<thead>
							<tr> <th>Name</th> <th>Email</th> <th>Phone</th> <th>Town</th> <th>Address</th> </tr>
						</thead>


						<tbody>
							
							<tr> <td> {{customer_name}} </td> <td> {{email}} </td> <td> {{phone}} </td> <td> {{town}} </td> <td> {{address}} </td> </tr>

						</tbody>

					</table>
				</div>

				</div>



				<div class="mt-3" v-if="pin">

					<p class="text-success">Rider pin : {{pin}} | Name : {{rider_name}} | Phone : {{rider_phone}} </p>
					
				</div>




				<div class="mt-3">
					
					<label>Change order status</label>
					<select class="form-select" v-model="status">
						<option>received</option>
						<option>preparing</option>
						<option>delivering</option>
						<option>arrived</option>
					</select>

					<button class="btn btn-1 mt-2" @click="change_order_status()" :disabled="disabled"> {{text}} </button>

				</div>




				<div class="mt-3">
					
					<label>Assign rider</label>
					<select class="form-select" v-model="rider_pin">
						<option :value="r.pin" v-for="r in riders"> {{r.pin}} - {{r.first_name}} {{r.last_name}} </option>
						
					</select>

					<button class="btn btn-1 mt-2" @click="assign_rider()" > Submit </button>

				</div>


			
		</div>


		</div> 


		


	</div> 
	
</template>



<script>
	import Sidebar from './layouts/Sidebar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import Swal from 'sweetalert2'



	export default{
		name : 'dashboard',
		components : {Sidebar,TopBar},

		data(){
			return{
			details : [],
			order_number : '',
			items : [],
			total_cost : 0,
			delivery_fee : 0,
			amount_to_pay : 0,
			status : '',
			payment : '',
			location : '',
			customer_name : '',
			email : '',
			phone : '',
			town : '',
			address : '',
			text : 'Save changes',
			disabled : false,
			riders : [],
			pin : '',
			rider_name : '',
			rider_phone : ''

		}},

		methods : {

			async assign_rider(){
				
				const res = await axios.post(this.$store.state.end_point+'api/assign-rider',{
					order_number : this.order_number,
					rider_pin : this.rider_pin
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.get_details()

			if(res=='success'){
				Swal.fire({
				  title: 'Success',
				  text: 'Rider assigned',
				  icon: 'success',
				  confirmButtonText: 'Close'
				})
			}else{
				Swal.fire({
				  title: 'Error!',
				  text: 'Something went wrong',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
			}
			this.get_riders()
		

			},


			async get_riders(){
				const res = await axios.get(this.$store.state.end_point+'api/get-riders').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
				
				this.riders = res 

				res.forEach((data)=>{
					if(this.pin==data.pin){
						this.rider_name = data.first_name +' '+data.last_name
						this.rider_phone = data.phone
					}
				})
			},

			async change_order_status(){
				this.text = 'Please wait...'
				this.disabled = true
				const res = await axios.post(this.$store.state.end_point+'api/change-order-status',{
					order_number : this.order_number,
					email : this.email,
					phone : this.phone,
					name : this.customer_name,
					status : this.status
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.get_details()

			if(res=='success'){
				Swal.fire({
				  title: 'Success',
				  text: 'Changes saved',
				  icon: 'success',
				  confirmButtonText: 'Close'
				})
			}else{
				Swal.fire({
				  title: 'Error!',
				  text: 'Something went wrong',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
			}

			this.text = 'Save changes'
			this.disabled = false

			},

			async get_details(){
				const res = await axios.get(this.$store.state.end_point+'api/order-details/'+this.order_number).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			
			res.forEach((data)=>{
				this.total_cost = data.total_cost
				this.delivery_fee = data.delivery_fee
				this.amount_to_pay = data.amount_to_pay
				this.status = data.status
				this.payment = data.payment
				this.location = data.location_name
				this.customer_name = data.first_name + data.last_name
				this.email = data.email
				this.phone = data.phone
				this.town = data.town
				this.address = data.address
				this.pin = data.rider_pin
			})

			},

			async get_order_items(){
				const res = await axios.get(this.$store.state.end_point+'api/get-order-items/'+this.order_number).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.items = res

			}



			
		},

		created(){
			this.order_number = this.$route.params.order_number
			this.get_order_items()
			this.get_details()	
			this.get_riders()

		}
	}
</script>

