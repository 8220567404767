<template>


<TopBar />

	<div class="main-container">
	<Sidebar /> 

		<div class="main"> 
		

		<div class="body-card shadow p-2 rounded">

			<div class="row">
			<div class="col-md-6">

				<h4>Settings</h4>
			</div>
				
			</div>


			<form @submit.prevent='change_settings()'>
				
				<div class="row">

					<div class="col-md-6 mt-2">
						<label>Username</label>
						<input type="text" disabled v-model="username" class="form-control">
					</div>


						<div class="col-md-6 mt-2">
						<label>Email</label>
						<input type="email" v-model="email" class="form-control">
					</div>


					<div class="col-md-6 mt-2">
						<label>Phone</label>
						<input type="text" v-model="phone" class="form-control">
					</div>

					<div class="col-md-6 mt-2">
						<label>Password</label>
						<input type="password" v-model="password" class="form-control" placeholder="*********">
					</div>

					<div class="col-md-6 mt-3">
						<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
					</div>
					
				</div>

			</form>

			
		</div>


		</div> 



	</div> 
	
</template>



<script>
	import Sidebar from './layouts/Sidebar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import Swal from 'sweetalert2'



	export default{
		name : 'dashboard',
		components : {Sidebar,TopBar},

		data(){
			return{
			username : '',
			email : '',
			phone : '',
			password : '',
			text : 'Save changes',
			disabled : false
		}},

		methods : {

			async change_settings(){

				if(!this.email){
					Swal.fire({
				  title: 'Error!',
				  text: 'Email  is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}

				if(!this.phone){
					Swal.fire({
				  title: 'Error!',
				  text: 'Phone  is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}


				this.text = 'Please wait...'
				this.disabled = true


				const res = await axios.post(this.$store.state.end_point+'api/change-settings',{
					id : this.id,
					email : this.email,
					phone : this.phone,
					password : this.password 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				if(res=='success'){
					Swal.fire({
				  title: 'Success',
				  text: 'Changes saved successfully',
				  icon: 'success',
				  confirmButtonText: 'Close'
				})
					localStorage.setItem('email',this.email)
					localStorage.setItem('phone',this.phone)
					
				}else{
					Swal.fire({
				  title: 'Error!',
				  text: res,
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
				}

				this.text = 'Save changes'
				this.disabled = false

		
			}			
		},

		created(){
		this.username = localStorage.getItem('username')
		this.id = localStorage.getItem('id')	
		this.email = localStorage.getItem('email')
		this.phone = localStorage.getItem('phone')
		}
	}
</script>

