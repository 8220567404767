<template>


<TopBar />

	<div class="main-container">
	<Sidebar /> 

		<div class="main"> 
		

		<div class="body-card shadow p-2 rounded">

			<div class="row">
			<div class="col-md-6">
				<h4>New Product Category</h4>
			</div>


			<div class="col-md-6" style="text-align:right;">
				<router-link to="/view-product-categories" class="btn btn-1"> <i class="bi bi-grid"></i> View categories</router-link>
			</div>
				
			</div>



			<div>

				<form @submit.prevent="new_product_category()" enctype="multipart/form-data">
					
					<div class="row">
						<div class="col-md-6 mt-2">
							<label>Category name</label>
							<input type="text" class="form-control" placeholder="Enter category name" v-model="name">
						</div>

						<div class="col-md-6 mt-2">
							<label>Image</label>
							<input @change="get_image" type="file" class="form-control" placeholder="Image">
						</div>


						<div class="col-md-12 mt-2">
							<label>Description</label>
							<textarea class="form-control" placeholder="Enter descripion" v-model="description" rows="6"></textarea>
						</div>


						<div class="col-md-12 mt-2">
							<button class="btn btn-1">Submit</button>
						</div>

					</div>

				</form>
				
			</div>
			
		</div>


		</div> 


		


	</div> 
	
</template>



<script>
	import Sidebar from './layouts/Sidebar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import Swal from 'sweetalert2'



	export default{
		name : 'dashboard',
		components : {Sidebar,TopBar},

		data(){
			return{
			name : '',
			image : '',
			description : ''	
		}},

		methods : {

			get_image(e){
				this.image = e.target.files[0]  
			},

			async new_product_category(){

				if(!this.name){
					Swal.fire({
				  title: 'Error!',
				  text: 'Category name is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}


				if(!this.image){
					Swal.fire({
				  title: 'Error!',
				  text: 'Image is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}

				const config = {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }

				const res = await axios.post(this.$store.state.end_point+'api/new-product-category',{
					name : this.name,
					image : this.image,
					description : this.description
				},config).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
				
				if(res=='success'){
					Swal.fire({
				  title: 'Success',
				  text: 'Category added successfully',
				  icon: 'success',
				  confirmButtonText: 'Close'
				})
				}else{

					Swal.fire({
				  title: 'Error!',
				  text: res,
				  icon: 'error',
				  confirmButtonText: 'Close'
				})

				}


			}
			
		},

		created(){
			
		}
	}
</script>

