<template>

<div :class=" {'blur' : show_modal} ">
	




	<Header />


<section style="background-image: url('/assets/images/Untitled-design-2.png'); padding-top: 0px; padding-bottom: 0px; background-color: #800; ">

	

<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">

		<div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>


  <div class="carousel-inner">


    <div class="carousel-item active">

    	<div class="container">


    		  <div class="row">
    		  	
    	<div class="col-md-1"></div>	  	
     	<div class="col-md-6 col-12" style="padding-top:100px;">
     		<div>

     			<div>
     			<h5 class="color-2 animate__animated animate__pulse">START OUR HEALTHY JOURNEY TODAY</h5>
     		</div>
     		<div>
     			<h1 class="text-white animate__animated animate__fadeInLeft" style="font-size: 7vw;"><b>Eat Clean Achara</b></h1>
     		</div>

     		<div>
     			<h6 class="text-white">Enjoy meals the healthy way with Eat Clean Achara</h6>
     		</div>

     		<div style="padding-top:10px;">
     			<router-link to="/all-menu" class="btn btn-2 animate__animated animate__pulse">Make an order</router-link>
     		</div>
     			
     		</div>
     		

     	</div>

     	<div class="col-md-4 col-12" style="padding-top: 60px;">
     	
     	<img class="animate__animated animate__zoomIn" src="/assets/images/imgbin_bento-barbecue-chicken-naked-chef-meal-prep-png.png" style="width: 100%;">

     	</div>
     	<div class="col-md-1"></div>
     	<div class="col-md-1"></div>

     	

     	<div class="col-md-10">
     		<h1 class="bottom-text animate__animated animate__zoomIn">Eat Clean Achara</h1>
     	</div>


     </div>
    		


    	</div>  

    </div>



    <div class="carousel-item">

    	<div class="container">


    		  <div class="row">
    		  	<div class="col-md-1"></div>

     	<div class="col-md-6 col-12" style="padding-top:100px;">
     		<div>

     			<div>
     			<h5 class="color-2 animate__animated animate__zoomIn">CHOOSE FROM A VARIETY OF MEALS</h5>
     		</div>
     		<div>
     			<h1 class="text-white animate__animated animate__zoomIn" style="font-size:7vw;"><b>Simply<br /> The Best</b></h1>
     		</div>

     		<div>
     			<h6 class="text-white">We  provide our customers with the freshet meals possible</h6>
     		</div>

     		<div style="padding-top:10px;">
     			<router-link to="/all-menu" class="btn btn-2 animate__animated animate__zoomIn">Make an order</router-link>
     		</div>
     			
     		</div>
     		

     	</div>

     	<div class="col-md-4 col-12" style="padding-top: 60px;">
     	
     	<img class="animate__animated animate__zoomIn" src="/assets/images/1-catalog-600x600-2.png" style="width: 100%;">

     	</div>

     	<div class="col-md-1"></div>

     	<div class="col-md-1"></div>

     	<div class="col-md-10">
     		<h1 class="bottom-text animate__animated animate__zoomIn">Chicken</h1>
     	</div>


     </div>
    		
    	</div>  

    </div>




    <div class="carousel-item">

    	<div>


    		  <div class="row">
 

     	<div class="col-md-4 animate__animated animate__zoomIn" style="background-image: url('/assets/images/output-onlinejpgtools.jpeg'); background-position: center; background-size: cover;"> 		

     	</div>


     	<div class="col-md-4 animate__animated animate__zoomIn" style="background-image: url('/assets/images/output-onlinejpgtools.jpeg'); min-height: 600px; background-position: center; background-size: cover; text-align: center; padding-top: 200px;"> 

     	<h1 class="text-white animate__animated animate__zoomIn" style="font-size: 60px;"><b>Be the best self</b></h1>	

     	<div style="padding-top:10px;">
     			<router-link to="/all-menu" class="btn btn-2 animate__animated animate__zoomIn">Make an order</router-link>
     		</div>	

     	</div>

     	<div class="col-md-4 animate__animated animate__zoomIn" style="background-image: url('/assets/images/output-onlinejpgtools.jpeg'); background-position: center; background-size: cover;"> 		

     	</div>


     </div>
    		
    	</div>  

    </div>




  </div>
  
 <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>


</div>


</section>





<section style="padding-top:50px; padding-bottom: 100px;">
	

	<div class="container">

		<div style="padding-bottom: 20px;">
			

			<div class="row">

				<div class="col-md-6 col-7">
				<h1>ORDER <span class="color-1"><b>ONLINE</b></span></h1>	
				</div>


				<div class="col-md-6 col-5" style="text-align: right;">
					<router-link to="/track-order" class="btn btn-1 shadow"> <i class="bi bi-truck"></i> Track Order</router-link>	
				</div>
				
			</div>

		</div>


		<div class="row">
			<div class="col-md-3 mt-3" v-for="c in categories">
				<router-link class="btn product-category" :to=" { name : 'menu-items', params : { id : c.id } } ":style=" { backgroundImage : 'url('+this.$store.state.end_point+'assets/product-category-images/'+c.image+')' } ">
					{{c.name}}
				</router-link>
			</div>


		</div>


		<div style="padding-top:20px;">
			<router-link to="/all-menu" class="btn btn-1 shadow"> <i class="bi bi-eye"></i> View All</router-link>
		</div>

		
	</div>


</section>





<section style="padding-bottom: 100px; background-color: #f5f5f5; padding-top: 100px;">
	

	<div class="container">


		<div style="text-align:center;">
			<h1>REAL, HEALTHY FOOD <span class="color-1"><b>THAT ACTUALLY TASTES GOOD.</b></span></h1>

		</div>


		<div style="text-align:center;">
			<h5>Choose from a wide variety of deliciously clean meals.</h5>
		</div>



		<div class="row" style="padding-top:50px;">

			<div class="col-md-4" style="text-align:center;">

				<img src="/assets/images/order-food-online-150x150.png">
				
				<h2>YOU CHOOSE</h2>
				<h5 class="color-3">
					Choose your ideal pack size and select from a wide variety of our deliciously clean meals.
				</h5>

			</div>


			<div class="col-md-4" style="text-align:center;">

				<img src="/assets/images/we-prepare-food-150x150.png">
				
				<h2>WE PREPARE</h2>
				<h5 class="color-3">
					We expertly prepare, weigh, and cook your meals with high-quality ingredients.
				</h5>

			</div>


			<div class="col-md-4" style="text-align:center;">

				<img src="/assets/images/enjoy-eat-clean-food-150x150.png">
				
				<h2>YOU ENJOY</h2>
				<h5 class="color-3">
					Your premium quality meals are ready to eat in minutes. Just heat and enjoy!
				</h5>

			</div>
			

		</div>
		
	</div>


</section>







<section style="padding-top:50px; padding-bottom: 100px;">
	

	<div class="container">

		<div style="padding-bottom: 20px;">
			

			<div class="row">

				<div class="col-md-6 col-6">
				<h1>OUR <span class="color-1"><b>MENU</b></span></h1>	
				</div>


				<div class="col-md-6 col-6" style="text-align: right;">
					<button class="btn btn-1 shadow"> <i class="bi bi-grid"></i> View all items</button>	
				</div>
				
			</div>

		</div>


		<div class="row">
			<div class="col-md-3 mt-3" v-for="p in products ">
				
				<div class="shadow">
					
					<div :style=" { backgroundImage : 'url('+this.$store.state.end_point+'assets/product-images/'+p.image+')' }" class="food-item-image"></div>

					<div>
						<button class="btn p-0"> <i class="bi bi-star-fill color-2"></i> </button>
						<button class="btn p-0"> <i class="bi bi-star-fill color-2"></i> </button>
						<button class="btn p-0"> <i class="bi bi-star-fill color-2"></i> </button>
						<button class="btn p-0"> <i class="bi bi-star-fill color-2"></i> </button>
						<button class="btn p-0"> <i class="bi bi-star-fill color-2"></i> </button>
					</div>

					<div style="padding-top:5px; padding-left: 5px;">
						<h6>{{p.name}}</h6>
					</div>

					<div class="row" style="padding:5px;">
						<div class="col-md-6">
							<h4 class="color-1">Ksh.{{p.price}}</h4>
						</div>
						<div class="col-md-6" style="text-align:right;">
							<i class="bi bi-check color-1"></i> {{p.category}}
						</div>

						<div class="col-md-6" style="text-align:right;">
							<router-link :to=" { name : 'product-details', params : { product_number : p.product_number } } " class="btn btn-4 w-100">View</router-link>
						</div>


						<div class="col-md-6" style="text-align:right;">
							<button @click="add_to_cart(p.product_number,p.price,p.name)" class="btn btn-3 w-100">Add to cart</button>
						</div>


					</div>



				</div>

			</div>


		</div>


		
	</div>


</section>




</div>



<div class="custom-modal" v-if="show_modal">


	<div class="row">
		<div class="col-md-4"></div>
		<div class="col-md-4">
			<div style="text-align:center; background-color: #fff;" class="rounded shadow-lg p-2 m-3">
				<h3> <i class="bi bi-info-circle color-1"></i> </h3>
				<h6>You must select a package size before adding a meal to cart </h6>
				<h5>Pick a package below</h5>

				<div>
					
					<button @click="select_package(p)" class="btn btn-1 m-1" v-for="p in packages"> {{p.name}} </button>

				</div>


				

				<div>
					<p class="color-3"><b>Please note</b> : Number of meals in cart must be equal to the package size </p>
				</div>


				<div>
					<button @click="show_modal=false" class="btn text-danger"> <i class="bi bi-x"></i> Close this window</button>
				</div>

			</div>
		</div>
		<div class="col-md-4"></div>
		
	</div>
	

</div>





<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import 'animate.css';
	export default{
		name : 'index',
		components : {Header,Footer},
		data(){
			return{
				categories : [],
				products : [],
				show_modal : false,
				item : '',
				packages : [],
				qty : 1,
				product_number : '',
				price : '',
				name : ''
			}
		},
		methods : {

			select_package(p){
				localStorage.setItem('package_id',p.id)
				localStorage.setItem('package_qty',p.qty)
				localStorage.setItem('package_discount',p.discount)
				localStorage.setItem('package_name',p.name)
				localStorage.setItem('is_package_selected','yes')
				this.$store.state.package_id = p.id
				this.$store.state.package_qty = p.qty
				this.$store.state.package_discount = p.discount
				this.$store.state.package_name = p.name 
				this.$store.state.is_package_selected = 'yes'
				
				if(!this.$store.state.total_qty){
					this.$store.state.total_qty = 1
				}

				this.qty = 1
				this.show_modal = false
				this.add_to_cart(this.product_number,this.price,this.name)
			},

			async get_packages(){
				const res = await axios.get(this.$store.state.end_point+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.packages = res

			},




			add_to_cart(product_number,price,name){
				this.product_number = product_number
				this.price = price
				this.name = name
				if(this.$store.state.is_package_selected=='no'){
					this.show_modal = true
				}else{
					
					this.item = { 'product_number' : product_number, 'qty' : this.qty, 'price' : price, 'name' : name }
					
					if(this.$store.state.total_qty_final>=this.$store.state.package_qty){
						//total qty is more than package size.Do not add item to cart
						Swal.fire({
				  title: 'Please note',
				  text: 'Quantity of items exceeds your package size',
				  icon: 'info',
				  confirmButtonText: 'Close'
				})
					}else{
						//increment final qty and add to cart

						if(!this.$store.state.total_qty_final){
							this.$store.state.total_qty_final = 0
						}

						this.$store.state.total_qty_final=parseInt(this.$store.state.total_qty_final) + parseInt(this.qty) 
						this.$store.state.cart = this.$store.state.cart.concat(this.item)
						Swal.fire({
				  title: 'Success',
				  text: 'Item added to cart',
				  icon: 'success',
				  confirmButtonText: 'Close'
				})
					console.log(this.$store.state.cart)

					}

					//automatically scroll to top
					window.scrollTo(0,0)

				}
			},

			async list_products(){
				const res = await axios.get(this.$store.state.end_point+'api/homepage-products').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				this.products = res 
		
			},

			async list_categories(){
				const res = await axios.get(this.$store.state.end_point+'api/get-homepage-categories').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				this.categories = res 
		
			}
		},

		created(){
			this.list_categories()
			this.list_products()
			this.get_packages()
		}
	}

</script>