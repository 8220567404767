<template>


<div :class=" {'blur' : show_modal} ">






<Header />

<section>
	
	<div class="container" style="text-align:center; padding-top: 50px; padding-bottom: 50px;">

		<div class="animate__animated animate__pulse">
			<h3 class="color-3">MENU</h3>
		</div>


		<div class="animate__animated animate__pulse">
			<h1> CHOOSE FROM A VARIETY OF MEALS </h1>
		</div>

		<div>
			<p>From breakfast to dinner, we got you covered.</p>
		</div>

		
	</div>


</section>





<section style="padding-bottom:100px;">
	

	<div class="container">



		<div class="row">
			<div class="col-md-3 mt-3" v-for="p in products ">
				
				<div class="shadow">
					
					<div :style=" { backgroundImage : 'url('+this.$store.state.end_point+'assets/product-images/'+p.image+')' }" class="food-item-image"></div>

					<div>
						<button class="btn p-0"> <i class="bi bi-star-fill color-2"></i> </button>
						<button class="btn p-0"> <i class="bi bi-star-fill color-2"></i> </button>
						<button class="btn p-0"> <i class="bi bi-star-fill color-2"></i> </button>
						<button class="btn p-0"> <i class="bi bi-star-fill color-2"></i> </button>
						<button class="btn p-0"> <i class="bi bi-star-fill color-2"></i> </button>
					</div>

					<div style="padding-top:5px; padding-left: 5px;">
						<h6>{{p.name}}</h6>
					</div>

					<div class="row" style="padding:5px;">
						<div class="col-md-6">
							<h4 class="color-1">Ksh.{{p.price}}</h4>
						</div>
						<div class="col-md-6" style="text-align:right;">
							<i class="bi bi-check color-1"></i> {{p.category}}
						</div>


						<div class="col-md-6" style="text-align:right;">
							<router-link :to=" { name : 'product-details', params : { product_number : p.product_number } } " class="btn btn-4 w-100">View</router-link>
						</div>


						<div class="col-md-6" style="text-align:right;">
							<button @click="add_to_cart(p.product_number,p.price,p.name)" class="btn btn-3 w-100">Add to cart</button>
						</div>


					</div>



				</div>

			</div>


		</div>




	
		
		<div style="text-align:center;" v-if="products.length==0">
			<h1> <i class="bi bi-info-circle color-1"></i> </h1>
			<p class="text-danger">No food items in this category at the moment</p>
			<router-link to="/all-menu" class="btn btn-1">Continue shopping</router-link>
		</div>



	</div>


</section>
	




</div>






<div class="custom-modal" v-if="show_modal">


	<div class="row">
		<div class="col-md-4"></div>
		<div class="col-md-4">
			<div style="text-align:center; background-color: #fff;" class="rounded shadow-lg p-2 m-3">
				<h3> <i class="bi bi-info-circle color-1"></i> </h3>
				<h6>You must select a package size before adding a meal to cart </h6>
				<h5>Pick a package below</h5>

				<div>
					
					<button @click="select_package(p)" class="btn btn-1 m-1" v-for="p in packages"> {{p.name}} </button>

				</div>


				

				<div>
					<p class="color-3"><b>Please note</b> : Number of meals in cart must be equal to the package size </p>
				</div>


				<div>
					<button @click="show_modal=false" class="btn text-danger"> <i class="bi bi-x"></i> Close this window</button>
				</div>

			</div>
		</div>
		<div class="col-md-4"></div>
		
	</div>
	

</div>



<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import Swal from 'sweetalert2'
	import axios from 'axios'
	import 'animate.css';
	export default{
		name : 'index',
		components : {Header,Footer},
		data(){
			return{
				products : [],
				categories : [],
				show_modal : false,
				item : '',
				packages : [],
				qty : 1,
				product_number : '',
				price : '',
				name : ''
			}
		},
		methods : {

			select_package(p){
				localStorage.setItem('package_id',p.id)
				localStorage.setItem('package_qty',p.qty)
				localStorage.setItem('package_discount',p.discount)
				localStorage.setItem('package_name',p.name)
				localStorage.setItem('is_package_selected','yes')
				this.$store.state.package_id = p.id
				this.$store.state.package_qty = p.qty
				this.$store.state.package_discount = p.discount
				this.$store.state.package_name = p.name 
				this.$store.state.is_package_selected = 'yes'
				
				if(!this.$store.state.total_qty){
					this.$store.state.total_qty = 1
				}

				this.qty = 1
				this.show_modal = false
				this.add_to_cart(this.product_number,this.price,this.name)
			},

			async get_packages(){
				const res = await axios.get(this.$store.state.end_point+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.packages = res

			},




			add_to_cart(product_number,price,name){
				this.product_number = product_number
				this.price = price
				this.name = name
				if(this.$store.state.is_package_selected=='no'){
					this.show_modal = true
				}else{
					
					this.item = { 'product_number' : product_number, 'qty' : this.qty, 'price' : price, 'name' : name }
					
					if(this.$store.state.total_qty_final>=this.$store.state.package_qty){
						//total qty is more than package size.Do not add item to cart
						Swal.fire({
				  title: 'Please note',
				  text: 'Quantity of items exceeds your package size',
				  icon: 'info',
				  confirmButtonText: 'Close'
				})
					}else{
						//increment final qty and add to cart

						if(!this.$store.state.total_qty_final){
							this.$store.state.total_qty_final = 0
						}

						this.$store.state.total_qty_final=parseInt(this.$store.state.total_qty_final) + parseInt(this.qty) 
						this.$store.state.cart = this.$store.state.cart.concat(this.item)
						Swal.fire({
				  title: 'Success',
				  text: 'Item added to cart',
				  icon: 'success',
				  confirmButtonText: 'Close'
				})
					console.log(this.$store.state.cart)

					}

					//automatically scroll to top
					window.scrollTo(0,0)

				}
			},

			async list_products(){
				const res = await axios.get(this.$store.state.end_point+'api/menu-items/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				this.products = res 
		
			},

			async list_categories(){
				const res = await axios.get(this.$store.state.end_point+'api/get-homepage-categories').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				this.categories = res 
		
			}

		},
		created(){
			this. id = this.$route.params.id
			this.list_products()
			this.list_categories()
			this.get_packages()
		}
	}

</script>