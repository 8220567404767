<template>
<Header />

<section>
	
	<div class="container" style="padding-top: 50px; padding-bottom: 50px;">

	

		<div class="row">
			<div class="col-md-1"></div>
			<div class="col-md-10">

				<div class="border-bottom" style="padding-bottom:10px;">
				<div class="row">
					<div class="col-md-8 col-12">

						<div v-if="this.$store.state.is_package_selected=='yes'">

							<i class="bi bi-stack color-1"></i> Fill your pack : 
						 {{this.$store.state.total_qty_final}}/{{this.$store.state.package_qty}} 

						<button style="margin-right:6px;" class="btn btn-1" :class=" { 'btn-2' : this.$store.state.package_name==p.name } " v-for="p in packages" @click="select_package(p)"> <i class="bi bi-check-circle" v-if="this.$store.state.package_name==p.name"></i> {{p.name}} </button>
						<button class="btn btn-danger" @click="clear_package()"> <i class="bi bi-x-circle"></i> Clear</button>
							
						</div>

						
					</div>
					<div class="col-md-4 col-12" style="text-align: right;">
						<router-link to="/all-menu" class="btn btn-1">Continue shopping</router-link>
					</div>
				</div>					
				</div>

				<div>
			<h3 class="color-3">Cart</h3>

			<div class="table-responsive">
				
				<table class="table table-hover">

					<thead>
						<tr>
							<th>Product</th> <th>Price</th> <th>Quantity</th> <th>Subtotal</th> <th>Action</th>
						</tr>
					</thead>

					<tbody v-for="c in this.$store.state.cart">					
							<tr v-for="p in products">
								
								<td v-if="p.product_number==c.product_number">
									{{p.name}}
								</td>

								<td v-if="p.product_number==c.product_number">
									Ksh.{{p.price}}
								</td>


								<td v-if="p.product_number==c.product_number">
									{{c.qty}}
								</td>

								<td v-if="p.product_number==c.product_number">
									Ksh.{{c.qty*c.price}}
								</td>

								<td v-if="p.product_number==c.product_number">
									<button @click="delete_item(c)" class="btn btn-danger"> <i class="bi bi-x"></i> </button>
								</td>

								

							</tr>
							

						
						
					</tbody>
					
				</table>

				<p v-if="this.$store.state.cart.length==0">No items in cart</p>


				<div class="row">

					<div class="col-md-6">
						<h6 class="color-3">Total cost : Ksh. {{total_cost}} </h6>
						<h6>Discount : Ksh. {{discount}} </h6>
						<h6>Amount to pay : Ksh. {{final_total_cost}} </h6>

					</div>


					<div class="col-md-6" style="text-align:right;">
						<button class="btn btn-1" @click="checkout()">Proceed to checkout</button>
					</div>
					
				</div>

			</div>


		</div>
			</div>
			<div class="col-md-1"></div>
			
		</div>
		
	</div>


</section>




<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import 'animate.css';
	export default{
		name : 'index',
		components : {Header,Footer},
		data(){
			return{
				packages : [],
				products : [],
				total_cost : 0,
				discount : 0,
				final_total_cost : 0
			}
		},
		methods : {

			delete_item(c){
				 
				let index = this.$store.state.cart.indexOf(c)
				if (index > -1) { // only splice array when item is found
					this.total_cost = 0
					this.final_total_cost = 0
				this.$store.state.total_qty_final=parseInt(this.$store.state.total_qty_final) - parseInt(c.qty)
				  this.$store.state.cart.splice(index, 1); // 2nd parameter means remove one item only
				  this.get_total_cost()
				}
			},

			select_package(p){
				localStorage.setItem('package_id',p.id)
				localStorage.setItem('package_qty',p.qty)
				localStorage.setItem('package_discount',p.discount)
				localStorage.setItem('package_name',p.name)
				localStorage.setItem('is_package_selected','yes')
				this.$store.state.package_id = p.id
				this.$store.state.package_qty = p.qty
				this.$store.state.package_discount = p.discount
				this.$store.state.package_name = p.name 
				this.$store.state.is_package_selected = 'yes'
				
				/*
				if(!this.$store.state.total_qty){
					this.$store.state.total_qty = 1
				}

				this.$store.state.total_qty_final = 0
				this.$store.state.total_qty_final = ''
				this.$store.state.cart = []

			this.$router.push('/')
			*/
			},

			checkout(){
				if(this.$store.state.total_qty_final<this.$store.state.package_qty){
					Swal.fire({
				  title: 'Please note',
				  text: 'Quantity of items is less than package size',
				  icon: 'info',
				  confirmButtonText: 'Close'
				})
				}else if(this.$store.state.total_qty_final>this.$store.state.package_qty){
					Swal.fire({
				  title: 'Please note',
				  text: 'Quantity of items is more than package size',
				  icon: 'info',
				  confirmButtonText: 'Close'
				})
				}else{
					this.$router.push('/checkout')
				}
			},

				get_total_cost(){
					this.$store.state.cart.forEach((c)=>{

						this.products.forEach((p)=>{

							if(c.product_number==p.product_number){

								this.total_cost = this.total_cost + ( parseInt(c.qty)*parseInt(c.price) )
								this.discount = parseInt( (this.$store.state.package_discount/100)*this.total_cost);
								this.final_total_cost = this.total_cost - this.discount
								this.$store.state.final_total_cost = this.final_total_cost

							}		 

						})

					})

				},

				async list_products(){
				const res = await axios.get(this.$store.state.end_point+'api/list-products').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				this.products = res 
					if(this.$store.state.cart.length>0){
				this.get_total_cost()
			}
			
			},

			clear_package(){
			localStorage.setItem('is_package_selected','no')	
			this.$store.state.is_package_selected = 'no'
			this.$store.state.total_qty = 1
			//clear final qty too
			this.$store.state.total_qty_final = ''
			this.$store.state.cart = []
			this.$router.push('/all-menu')
			},


			async get_packages(){
				const res = await axios.get(this.$store.state.end_point+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.packages = res

			},
		},

		created(){
			this.get_packages()
			this.list_products()
			
		}
	}

</script>