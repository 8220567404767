<template>


<TopBar />

	<div class="main-container">
	<Sidebar /> 

		<div class="main"> 
		<h6 class="color-3">Logged in as <b>{{this.$store.state.username}}</b> </h6>
		

		<div class="row">
			<div class="col-md-6">
			<h3 class="color-3">Welcome Back!</h3>
			</div>
			<div class="col-md-6" style="text-align:right;">
				<button class="btn color-3" style="text-align:left; border: solid 1px #CDFFD9;"> <i class="bi bi-calendar2-check"></i> <span class="color-4">|</span> {{new Date()}} </button>
			</div>
		</div>

		<div class="row">

			<div class="col-md-3 mt-3">
					<div class="shadow stat-card">
						<div class="row">
						<div class="col-md-8 col-8">
							<h4 class="color-1"> {{categories.length}} </h4>
							<p>Product Categories</p>
						</div>
						<div class="col-md-4 col-4" style="text-align:right;">
							<h1> <i class="bi bi-grid color-1"></i> </h1>
						</div>
					</div>

					<div style="border-top: solid 1px #FFF0DC;">
						<router-link class="btn" to="/view-product-categories"> <i class="bi bi-eye color-1"></i> View categories</router-link>
					</div>
						
					</div>
	
				</div>



				<div class="col-md-3 mt-3">
					<div class="shadow stat-card">
						<div class="row">
						<div class="col-md-8 col-8">
							<h4 class="color-1"> {{products.length}} </h4>
							<p>Products</p>
						</div>
						<div class="col-md-4 col-4" style="text-align:right;">
							<h1> <i class="bi bi-layers color-1"></i> </h1>
						</div>
					</div>

					<div style="border-top: solid 1px #FFF0DC;">
						<router-link class="btn" to="/view-products"> <i class="bi bi-eye color-1"></i> View products</router-link>
					</div>
						
					</div>
	
				</div>



				<div class="col-md-3 mt-3">
					<div class="shadow stat-card">
						<div class="row">
						<div class="col-md-8 col-8">
							<h4 class="color-1"> {{packages.length}} </h4>
							<p>Packages</p>
						</div>
						<div class="col-md-4 col-4" style="text-align:right;">
							<h1> <i class="bi bi-collection color-1"></i> </h1>
						</div>
					</div>

					<div style="border-top: solid 1px #FFF0DC;">
						<router-link class="btn" to="/view-packages"> <i class="bi bi-eye color-1"></i> View packages</router-link>
					</div>
						
					</div>
	
				</div>



				<div class="col-md-3 mt-3">
					<div class="shadow stat-card">
						<div class="row">
						<div class="col-md-8 col-8">
							<h4 class="color-1"> {{orders.length}} </h4>
							<p>Orders</p>
						</div>
						<div class="col-md-4 col-4" style="text-align:right;">
							<h1> <i class="bi bi-list color-1"></i> </h1>
						</div>
					</div>

					<div style="border-top: solid 1px #FFF0DC;">
						<router-link class="btn" to="/orders"> <i class="bi bi-eye color-1"></i> View orders</router-link>
					</div>
						
					</div>
	
				</div>


			
		</div>








		<div class="body-card shadow p-2 rounded mt-3">

			<div class="row">
			<div class="col-md-6">
				<h4>Orders</h4>
			</div>


				
			</div>



			<div class="table-responsive">

				<table class="table">

					<thead>
						<tr>
							<th>Order#</th> <th> Total cost </th> <th>Delivery fee</th> <th>Amount to pay</th> <th>Delivery location</th> <th>Payment</th> <th>Action</th>
						</tr>
					</thead>

					<tbody>
					
					<tr v-for="o in orders">
						
						<td> {{o.order_number}} </td>
						<td> Ksh.{{o.total_cost}} </td>
						<td> Ksh.{{o.delivery_fee}} </td>
						<td> Ksh.{{o.amount_to_pay}} </td>
						<td> {{o.location_name}} </td>
						<td> <span :class="{ 'text-success' : o.payment=='completed', 'text-danger' : o.payment=='pending' }"> <i :class="{ 'bi bi-check' : o.payment=='completed', 'bi bi-x' : o.payment=='pending' }"></i> {{o.payment}}</span> </td>
						<td>
							<div class="dropdown">
  <button class="btn btn-4 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Manage
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
   <li><router-link :to="{ name : 'view-order', params : { order_number : o.order_number } }" class="dropdown-item">View</router-link></li>
    
  </ul>
</div>
						</td>

					</tr>

					</tbody>
					
				</table>
				
			</div>
			
		</div>



		








		</div> 


		


	</div> 
	
</template>



<script>
	import Sidebar from './layouts/Sidebar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'


	export default{
		name : 'dashboard',
		components : {Sidebar,TopBar},

		data(){
			return{
				orders : [],
				packages : [],
				products : [],
				categories : []
		}},

		methods : {

			async list_categories(){
				const res = await axios.get(this.$store.state.end_point+'api/list-categories').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				this.categories = res 
		
			},

			async list_products(){
				const res = await axios.get(this.$store.state.end_point+'api/list-products').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				this.products = res 
		
			},

			async get_packages(){
				const res = await axios.get(this.$store.state.end_point+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.packages = res

			},

			async get_orders(){
				const res = await axios.get(this.$store.state.end_point+'api/get-orders').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.orders = res

			}
		},

		created(){
			this.get_orders()
			this.list_categories()
			this.list_products()
			this.get_packages()
		this.$store.state.username = localStorage.getItem('username')	
		}
	}
</script>

