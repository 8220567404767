<template>
<Header />

<section>
	
	<div class="container" style="text-align:center; padding-top: 50px; padding-bottom: 50px;">

		<div class="animate__animated animate__pulse">
			<h3 class="color-3">YOUR RIGHT TO KNOW</h3>
		</div>


		<div class="animate__animated animate__pulse">
			<h1 class="color-1"> GOT A QUESTION?GET YOUR ANSWER</h1>
		</div>


		<div class="animate__animated animate__pulse">
			<p>
			Curious about our services, here are our frequently asked questions.
			</p>
		</div>
		
	</div>


</section>





<section style="padding-bottom:100px;">
	

	<div class="container">

		<div class="row">

		<div class="col-md-12">

			<div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        When can I get my meals
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p>
     To get meals delivered the next day, please make your order before 7pm. If you order after 7pm, your meals will be delivered the day after next.
 </p>

 <p>
We understand that sometimes you may need to make a last-minute order, and we will do our best to accommodate you. However, if you order after 7pm, there is a chance that your meals may not be available for delivery the next day.
        </p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Why choose Eat Clean Achara
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p>
        	At EatClean Achara, we believe that everyone deserves to enjoy delicious and healthy food. That’s why we use only the freshest and highest-quality ingredients, and we make it low in calories and sugar. We also make our meals in a clean and sanitary environment, you can be sure that it’s safe to eat.

        </p>
        <p>
        	Order your meals today and see the difference!

        </p>
      </div>
    </div>
  </div>

</div>



			
		</div>

			
		</div>
		
	</div>


</section>





<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	export default{
		name : 'index',
		components : {Header,Footer}
	}

</script>