<template>
<Header />

<section>
	
	<div class="container" style="text-align:center; padding-top: 50px; padding-bottom: 50px;">

		<div class="animate__animated animate__pulse">
			<h3 class="color-1">How It Works</h3>
		</div>


		<div class="animate__animated animate__pulse">
			<h1> TASTE THE QUALITY IN <b>EVERY MEAL WE MAKE.</b> </h1>
		</div>


		<div class="animate__animated animate__pulse">
			<p>
				We provide fully cooked meals with the nutrients you<br/> need to change your body and the way you feel about dieting. The only commitment you have to make is to yourself.
			</p>
		</div>
		
	</div>


</section>




<section style="padding-bottom: 100px; background-color: #f5f5f5; padding-top: 100px;">
	

	<div class="container">


		<div style="text-align:center;">
			<h1>REAL, HEALTHY FOOD <span class="color-1"><b>THAT ACTUALLY TASTES GOOD.</b></span></h1>

		</div>


		<div style="text-align:center;">
			<h5>Choose from a wide variety of deliciously clean meals.</h5>
		</div>



		<div class="row" style="padding-top:50px;">

			<div class="col-md-4" style="text-align:center;">

				<img src="/assets/images/order-food-online-150x150.png">
				
				<h2>YOU CHOOSE</h2>
				<h5 class="color-3">
					Choose your ideal pack size and select from a wide variety of our deliciously clean meals.
				</h5>

			</div>


			<div class="col-md-4" style="text-align:center;">

				<img src="/assets/images/we-prepare-food-150x150.png">
				
				<h2>WE PREPARE</h2>
				<h5 class="color-3">
					We expertly prepare, weigh, and cook your meals with high-quality ingredients.
				</h5>

			</div>


			<div class="col-md-4" style="text-align:center;">

				<img src="/assets/images/enjoy-eat-clean-food-150x150.png">
				
				<h2>YOU ENJOY</h2>
				<h5 class="color-3">
					Your premium quality meals are ready to eat in minutes. Just heat and enjoy!
				</h5>

			</div>
			

		</div>
		
	</div>


</section>




<section style="padding-top:100px; padding-bottom: 50px;">
	

	<div class="container">


		<img src="/assets/images/eatclean-3-2.png" style="width: 100%;">
		
	</div>


</section>






<section style="padding-bottom:60px;">
	

	<div class="container">

		<div class="row">

			<div class="col-md-2"></div>
			<div class="col-md-8">
				<h3 class="color-3">ORDER NOW</h3>
				<h1 class="color-1">
					YOUR HEALTHY LIFESTYLE STARTS HERE.
				</h1>
				<h5>
					Whether you're trying to lose weight, gain muscle, or simply live a healthier lifestyle, we have the meals to help you reach your goals.

				</h5>

				<div style="padding-top:5px;">
					<router-link to="/all-menu" class="btn btn-1">Make an order</router-link>
				</div>

			</div>

			<div class="col-md-2"></div>
			
		</div>
		
	</div>


</section>




<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	export default{
		name : 'index',
		components : {Header,Footer}
	}

</script>