<template>


<TopBar />

	<div class="main-container">
	<Sidebar /> 

		<div class="main"> 
		

		<div class="body-card shadow p-2 rounded">

			<div class="row">
			<div class="col-md-6">
				<h4>New Package</h4>
			</div>


			<div class="col-md-6" style="text-align:right;">
				<router-link to="/view-packages" class="btn btn-1"> <i class="bi bi-grid"></i> View packages</router-link>
			</div>
				
			</div>



			<div>

				<form @submit.prevent="new_package()" enctype="multipart/form-data">
					
					<div class="row">
						<div class="col-md-4 mt-2">
							<label>Package name</label>
							<input type="text" class="form-control" placeholder="Enter package name" v-model="name">
						</div>


						<div class="col-md-4 mt-2">
							<label>Quantity</label>
							<input type="number" min="1" class="form-control" placeholder="Enter number of items in the package" v-model="qty">
						</div>

						<div class="col-md-4 mt-2">
							<label>Discount(%)</label>
							<input type="number" min="0"  class="form-control" placeholder="Enter number of items in the package" v-model="discount">
						</div>




						<div class="col-md-12 mt-2">
							<button class="btn btn-1">Submit</button>
						</div>

					</div>

				</form>
				
			</div>
			
		</div>


		</div> 


		


	</div> 
	
</template>



<script>
	import Sidebar from './layouts/Sidebar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import Swal from 'sweetalert2'



	export default{
		name : 'dashboard',
		components : {Sidebar,TopBar},

		data(){
			return{
			name : '',
			qty : '',
			discount : ''	
		}},

		methods : {

			async new_package(){

				if(!this.name){
					Swal.fire({
				  title: 'Error!',
				  text: 'Package name is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}


				if(!this.qty){
					Swal.fire({
				  title: 'Error!',
				  text: 'Quantity is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}

				

				const res = await axios.post(this.$store.state.end_point+'api/new-package',{
					name : this.name,
					qty : this.qty,
					discount : this.discount
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
				
				if(res=='success'){
					Swal.fire({
				  title: 'Success',
				  text: 'Package added successfully',
				  icon: 'success',
				  confirmButtonText: 'Close'
				})
					this.name = ''
					this.discount = ''
					this.qty = ''
				}else{

					Swal.fire({
				  title: 'Error!',
				  text: res,
				  icon: 'error',
				  confirmButtonText: 'Close'
				})

				}


			}
			
		},

		created(){
			
		}
	}
</script>

