<template>


<TopBar />

	<div class="main-container">
	<Sidebar /> 

		<div class="main"> 
		

		<div class="body-card shadow p-2 rounded">

			<div class="row">
			<div class="col-md-6">
				<h4>Riders</h4>
			</div>


			<div class="col-md-6" style="text-align:right;">
				<router-link to="/new-rider" class="btn btn-1"> <i class="bi bi-plus"></i> New rider</router-link>
			</div>
				
			</div>



			<div class="table-responsive">

				<table class="table table-hover table-striped">
					<thead>
						<tr>
							<th>Name</th> <th>Email</th> <th>Phone</th> <th>PIN</th> <th>Action</th>
						</tr>
					</thead>


					<tbody>
						
						<tr v-for="r in riders">
							<td> {{r.first_name}} {{r.last_name}} </td>
							<td> {{r.email}} </td>
							<td> {{r.phone}}</td>
							<td> {{r.pin}}</td>

							<td>
						<div class="dropdown">
  <button class="btn btn-4 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Manage
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <!--<li><router-link :to="{ name : 'edit-product', params : {product_number:p.product_number} }" class="dropdown-item">Edit</router-link></li>-->
    <li><button @click="delete_rider(r.id)" class="dropdown-item">Delete</button></li>
  </ul>
</div>
					</td>

						</tr>

					</tbody>

				</table>
				
				
			</div>
			
		</div>


		</div> 


		


	</div> 
	
</template>



<script>
	import Sidebar from './layouts/Sidebar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import Swal from 'sweetalert2'



	export default{
		name : 'dashboard',
		components : {Sidebar,TopBar},

		data(){
			return{
			riders : []	
		}},

		methods : {

			async delete_rider(id){
				const res = await axios.post(this.$store.state.end_point+'api/delete-rider',{
					id : id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
				
				this.get_riders()
			},

			async get_riders(){
				const res = await axios.get(this.$store.state.end_point+'api/get-riders').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
				
				this.riders = res 
			}
			
		},

		created(){
			this.get_riders()
		}
	}
</script>

