<template>

<div :class=" { 'blur' : show_modal } ">
	


<Header />

<section>
	
	<div class="container" style="padding-top: 50px; padding-bottom: 50px;">

		<div class="row">

			<div class="col-md-1"></div>

			<div class="col-md-5">
				<img src="/assets/images/low carb.png" class="product-image rounded">
			</div>

			<div class="col-md-6" style="padding-top:30px; background-attachment: scroll;">
				
				<div class="alert alert-success" v-if="this.$store.state.cart.length>0">

					<div class="row">
						<div class="col-md-6">
							You have <b>[{{this.$store.state.total_qty_final}}]</b> items in cart
						</div>
						<div class="col-md-6" style="text-align: right;">
							<router-link style="margin-right: 10px;" class="btn btn-3" to="/all-menu">Continue shopping</router-link>
							<router-link class="btn btn-3" to="/cart">View cart</router-link>
						</div>
					</div>
					
				</div>

				<h5> {{name}} </h5>

				<p class="color-3"> <i class="bi bi-check color-1"></i> {{category}} </p>

				<h2 class="color-1"><b>KSH.{{price}}</b></h2>
				

				<div v-if="this.$store.state.is_package_selected!='yes'">
					<p><span class="color-3">You have not selected your package yet</span><br />
					Select a package below</p>
					<div>
					<button @click="select_package(p)" class="btn btn-1 m-1" v-for="p in packages"> {{p.name}} </button>

				</div>
				</div>

				<div v-if="this.$store.state.is_package_selected=='yes'">
					
						<div>
					<button class="btn btn-1" @click="decrement_qty()"> <i class="bi bi-dash"></i> </button>
					{{qty}}
					<button @click="increment_qty()" class="btn btn-1"> <i class="bi bi-plus"></i> </button>
				</div>

				<div class="mt-3">
					<p>Selected package : <span class="color-1"> {{this.$store.state.package_name}} </span> <button class="btn btn-4" @click="clear_package()">Clear & pick a new one</button>  </p>
					<button class="btn btn-2" @click="add_to_cart()">Add to cart</button>
				</div>

				</div>



				<div style="padding-top:40px;">
					
					<div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Nutitrtion facts
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
       
       <div v-html="nutrition"></div>

      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Ingredients
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div v-html="ingredients"></div>
      </div>
    </div>
  </div>


<div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        Description
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div v-html="description"></div>
      </div>
    </div>
  </div>



  <div class="accordion-item">
    <h2 class="accordion-header" id="headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        Heating instructions
      </button>
    </h2>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div v-html="heating_instructions"></div>
      </div>
    </div>
  </div>




</div>

				</div>

			

			</div>
			
		</div>

	
	</div>


</section>



<Footer />





</div>


<div class="custom-modal" v-if="show_modal">


	<div class="row">
		<div class="col-md-4"></div>
		<div class="col-md-4">
			<div style="text-align:center; background-color: #fff;" class="rounded shadow-lg p-2 m-3">
				<h3> <i class="bi bi-info-circle color-1"></i> </h3>
				<h6>You must select a package size before adding a meal to cart </h6>
				<h5>Pick a package below</h5>

				<div>
					
					<button @click="select_package(p)" class="btn btn-1 m-1" v-for="p in packages"> {{p.name}} </button>

				</div>


				

				<div>
					<p class="color-3"><b>Please note</b> : Number of meals in cart must be equal to the package size </p>
				</div>


				<div>
					<button @click="show_modal=false" class="btn text-danger"> <i class="bi bi-x"></i> Close this window</button>
				</div>

			</div>
		</div>
		<div class="col-md-4"></div>
		
	</div>
	

</div>



</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import 'animate.css';

	export default{
		name : 'index',
		components : {Header,Footer},
		data(){
			return{
				name : '',
				description : '',
				image : '',
				price : '',
				ingredients : '',
				nutrition : '',
				product_type : '',
				category : '',
				product_number : '',
				heating_instructions : '',
				packages : [],
				show_modal : false,
				item : '',
				qty : 1
			}
		},

		methods : {

			increment_qty(){

				if(this.$store.state.total_qty>=this.$store.state.package_qty || this.$store.state.total_qty_final>=this.$store.state.package_qty || this.qty>=this.$store.state.package_qty || (parseInt(this.$store.state.total_qty_final)+parseInt(this.qty))>=this.$store.state.package_qty ){
					Swal.fire({
				  title: 'Please note',
				  text: 'Quantity of items exceeds your package size',
				  icon: 'info',
				  confirmButtonText: 'Close'
				})
				}else{
					this.$store.state.total_qty++
					this.qty++
				}
			},

			decrement_qty(){

				if(this.qty==1){
					//pass
				}else{
					this.qty--
					this.$store.state.total_qty--
				}
				
			},

			clear_package(){
			localStorage.setItem('is_package_selected','no')	
			this.$store.state.is_package_selected = 'no'
			this.item = ''
			this.qty = 1
			this.$store.state.total_qty = 1
			//clear final qty too
			this.$store.state.total_qty_final = ''
			this.$store.state.cart = []
			this.show_modal = true
			},

			select_package(p){
				localStorage.setItem('package_id',p.id)
				localStorage.setItem('package_qty',p.qty)
				localStorage.setItem('package_discount',p.discount)
				localStorage.setItem('package_name',p.name)
				localStorage.setItem('is_package_selected','yes')
				this.$store.state.package_id = p.id
				this.$store.state.package_qty = p.qty
				this.$store.state.package_discount = p.discount
				this.$store.state.package_name = p.name 
				this.$store.state.is_package_selected = 'yes'
				
				if(!this.$store.state.total_qty){
					this.$store.state.total_qty = 1
				}

				this.qty = 1
				this.show_modal = false
			},

			add_to_cart(){
				if(this.$store.state.is_package_selected=='no'){
					this.show_modal = true
				}else{
					
					this.item = { 'product_number' : this.product_number, 'qty' : this.qty, 'price' : this.price, 'name' : this.name }
					
					if(this.$store.state.total_qty_final>=this.$store.state.package_qty){
						//total qty is more than package size.Do not add item to cart
						Swal.fire({
				  title: 'Please note',
				  text: 'Quantity of items exceeds your package size',
				  icon: 'info',
				  confirmButtonText: 'Close'
				})
					}else{
						//increment final qty and add to cart

						if(!this.$store.state.total_qty_final){
							this.$store.state.total_qty_final = 0
						}

						this.$store.state.total_qty_final=parseInt(this.$store.state.total_qty_final) + parseInt(this.qty) 
						this.$store.state.cart = this.$store.state.cart.concat(this.item)
						Swal.fire({
				  title: 'Success',
				  text: 'Item added to cart',
				  icon: 'success',
				  confirmButtonText: 'Close'
				})
					console.log(this.$store.state.cart)

					}

					//automatically scroll to top
					window.scrollTo(0,0)

				}
			},


			async get_packages(){
				const res = await axios.get(this.$store.state.end_point+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.packages = res

			},

			async product_details(){
				const res = await axios.get(this.$store.state.end_point+'api/product-details-2/'+this.product_number).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				res.forEach((data)=>{
					this.category = data.category
					this.name = data.name
					this.product_type = data.product_type
					this.price = data.price 
					this.description = data.description
					this.ingredients = data.ingredients
					this.nutrition = data.nutrition
					this.heating_instructions = data.heating_instructions

				})

				if(this.product_type=='variable'){
					//this.get_product_sizes()
				}
		
			},
		},


		created(){
			this.product_number = this.$route.params.product_number
			this.product_details()
			this.get_packages()

		}


	}

</script>