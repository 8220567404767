<template>
	
	<div style="text-align: center; padding-top: 30px; padding-bottom: 5px;">
		

		<div>
			<h6>Eat Clean Achara</h6>
			<h6 class="color-3">Eat Clean Achara © 2024. All Rights Reserved</h6>
		</div>



	</div>


</template>