<template>


<TopBar />

	<div class="main-container">
	<Sidebar /> 

		<div class="main"> 
		

		<div class="body-card shadow p-2 rounded">

			<div class="row">
			<div class="col-md-6">
				<h4>Delivery Locations</h4>
			</div>


			<div class="col-md-6" style="text-align:right;">
				<router-link to="/new-location" class="btn btn-1"> <i class="bi bi-plus"></i> New location</router-link>
			</div>
				
			</div>



			<div>

				<table class="table table-hover table-striped">
					<thead>
						<tr> <th>Name</th> <th>Delivery fee</th> <th>Actions</th> </tr>
					</thead>

					<tbody>
						
						<tr v-for="l in locations">
							<td> {{l.name}} </td> <td> Ksh.{{l.price}} </td> <td> <div class="dropdown">
  <button class="btn btn-4 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Manage
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><router-link :to="{ name : 'edit-location', params : {id:l.id} }" class="dropdown-item">Edit</router-link></li>
    <li><button @click="delete_location(l.id)" class="dropdown-item">Delete</button></li>
  </ul>
</div> </td>
						</tr>

					</tbody>

				</table>
				
			</div>
			
		</div>


		</div> 


		


	</div> 
	
</template>



<script>
	import Sidebar from './layouts/Sidebar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import Swal from 'sweetalert2'



	export default{
		name : 'dashboard',
		components : {Sidebar,TopBar},

		data(){
			return{
			locations : []	
		}},

		methods : {

			async get_locations(){
				
				const res = await axios.get(this.$store.state.end_point+'api/get-locations').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
				
				this.locations = res

			},


			async delete_location(id){
				
				const res = await axios.post(this.$store.state.end_point+'api/delete-location',{id : id }).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
				
				this.get_locations()

			}
			
		},

		created(){
			this.get_locations()	
		}
	}
</script>

