import { createRouter, createWebHistory } from 'vue-router'
import Index from '../components/Index'
import HowItWorks from '../components/HowItWorks'
import Contact from '../components/Contact'
import Faqs from '../components/Faqs'
import AdminDashboard from '../components/AdminDashboard'
import NewProductCategory from '../components/NewProductCategory'
import ViewProductCategories from '../components/ViewProductCategories'
import EditProductCategory from '../components/EditProductCategory'
import NewProduct from '../components/NewProduct'
import ViewProducts from '../components/ViewProducts'
import EditProduct from '../components/EditProduct'
import AdminLogin from '../components/AdminLogin'
import Settings from '../components/Settings'
import NewPackage from '../components/NewPackage'
import ViewPackages from '../components/ViewPackages'
import EditPackage from '../components/EditPackage'
import ProductDetails from '../components/ProductDetails'
import Cart from '../components/Cart'
import Checkout from '../components/Checkout'
import NewLocation from '../components/NewLocation'
import ViewLocations from '../components/ViewLocations'
import EditLocation from '../components/EditLocation'
import Orders from '../components/Orders'
import ViewOrder from '../components/ViewOrder'
import AllMenu from '../components/AllMenu'
import MenuItems from '../components/MenuItems'
import TrackOrder from '../components/TrackOrder'
import NewRider from '../components/NewRider'
import ViewRiders from '../components/ViewRiders'
import RiderLogin from '../components/RiderLogin'
import RiderDashboard from '../components/RiderDashboard'



const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },

    {
    path: '/how-it-works',
    name: 'how-it-works',
    component: HowItWorks
  },


      {
    path: '/contact',
    name: 'contact',
    component: Contact
  },


       {
    path: '/faqs',
    name: 'faqs',
    component: Faqs
  },


       {
    path: '/dashboard',
    name: 'dashboard',
    component: AdminDashboard
  },



       {
    path: '/new-product-category',
    name: 'new-product-category',
    component: NewProductCategory
  },



    {
    path: '/view-product-categories',
    name: 'view-product-categories',
    component: ViewProductCategories
  },

   {
    path: '/edit-product-category/:id',
    name: 'edit-product-category',
    component: EditProductCategory
  },


   {
    path: '/new-product',
    name: 'new-product',
    component: NewProduct
  },


    {
    path: '/view-products',
    name: 'view-products',
    component: ViewProducts
  },

  {
    path: '/edit-product/:product_number',
    name: 'edit-product',
    component: EditProduct
  },


    {
    path: '/sudo',
    name: 'sudo',
    component: AdminLogin
  },


     {
    path: '/settings',
    name: 'settings',
    component: Settings
  },



     {
    path: '/new-package',
    name: 'new-package',
    component: NewPackage
  },


     {
    path: '/view-packages',
    name: 'view-packages',
    component: ViewPackages
  },


     {
    path: '/edit-package/:id',
    name: 'edit-package',
    component: EditPackage
  },


     {
    path: '/product-details/:product_number',
    name: 'product-details',
    component: ProductDetails
  },

    {
    path: '/cart',
    name: 'cart',
    component: Cart
  },


  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout
  },


{
    path: '/new-location',
    name: 'new-location',
    component: NewLocation
  },


{
    path: '/view-locations',
    name: 'view-locations',
    component: ViewLocations
  },


{
    path: '/edit-location/:id',
    name: 'edit-location',
    component: EditLocation
  },


{
    path: '/orders',
    name: 'orders',
    component: Orders
  },


  {
    path: '/view-order/:order_number',
    name: 'view-order',
    component: ViewOrder
  },

{
    path: '/all-menu',
    name: 'all-menu',
    component: AllMenu
  },


  {
    path: '/menu-items/:id',
    name: 'menu-items',
    component: MenuItems
  },


{
    path: '/track-order',
    name: 'track-order',
    component: TrackOrder
  },


{
    path: '/new-rider',
    name: 'new-rider',
    component: NewRider
  },

{
    path: '/view-riders',
    name: 'view-riders',
    component: ViewRiders
  },


{
    path: '/riders',
    name: 'rider',
    component: RiderLogin
  },


  {
    path: '/rider-dashboard',
    name: 'rider-dashboard',
    component: RiderDashboard
  },



 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
