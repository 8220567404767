<template>
	
	<nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
  <div class="container-fluid">
    <router-link class="navbar-brand" to="/">
    	<img src="/assets/images/logo.png" class="logo">
    </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link to="/" class="nav-link active" aria-current="page">Home</router-link>
        </li>

         <li class="nav-item dropdown">
          <router-link to="all-menu" class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Menu
          </router-link>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><router-link to="/all-menu" class="dropdown-item">View All</router-link></li>


            <li v-for="c in categories"><router-link :to=" { name : 'menu-items', params : { id : c.id } } " class="dropdown-item"> {{c.name}} </router-link></li>


          </ul>
        </li>

        <li class="nav-item">
          <router-link to="/how-it-works" class="nav-link">How It Works</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/contact" class="nav-link">Contact Us</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/faqs" class="nav-link">FAQ</router-link>
        </li>

    
      </ul>
      <div class="d-fle">
      	<button @click="show_form=!show_form" class="btn btn-lg icon-hover"> <i class="bi bi-search"></i> </button>
      	<router-link to="/cart" class="btn btn-lg icon-hover"> <i class="bi bi-cart"></i> <sup><small class="color-1"> <span v-if='!this.$store.state.total_qty_final'>0</span> <span v-if='this.$store.state.total_qty_final'>{{this.$store.state.total_qty_final}}</span> </small></sup> </router-link>
        <router-link to="/track-order" class="btn btn-1"> <i class="bi bi-truck"></i> Track Order </router-link>
      </div>
    </div>
  </div>
</nav>



<div style="z-index: 900; position: fixed; width: 100%; padding-top: 30px;" v-if="show_form">
  

  <div class="row">

    <div class="col-md-3"></div>
    <div class="col-md-6">
      
      <div style="background-color: #fff;" class="shadow-lg rounded p-3 animate__animated animate__lightSpeedInRight">
        
        <div class="row">
          <div class="col-md-6 col-6">
            <h6>Search for a meal</h6>
          </div>
          <div class="col-md-6 col-6" style="text-align:right;">
            
            <button @click="show_form=!show_form" class="btn text-danger"> <i class="bi bi-x"></i> </button>

          </div>
        </div>


        <div>
          <input @input="search_meal()" v-model="search_item" type="search" placeholder="Enter meal to search" class="form-control">
        </div>



        <div class="table-responsive">

          <table class="table table-striped table-hover">

          <tbody>
            <tr v-for="p in products">
              <td>  <router-link :to=" { name : 'product-details', params : { product_number : p.product_number } } " class=""> {{p.name}} </router-link> </td>
            </tr>
          </tbody>
          
        </table>
          
        </div>


        <div style="text-align:center;" class="mt-3">
          <button @click="show_form=!show_form" class="btn text-danger"> <i class="bi bi-x"></i> Close this window</button>
        </div>

      </div>

    </div>
    <div class="col-md-3"></div>
    
  </div>


</div>



</template>


<script>
  import axios from 'axios'
  export default{
    name : 'header',
    data(){
      return{
        categories : [],
        products : [],
        search_item : '',
        show_form : false
      }
    },

    methods : {

      async search_meal(){
        const res = await axios.post(this.$store.state.end_point+'api/search-meal',{
          search_item : this.search_item
        }).then(function(response){
          return response.data
        }).catch(function(error){
          //pass
          console.log(error)
        })
      
      this.products = res

      },

      async list_categories(){
        const res = await axios.get(this.$store.state.end_point+'api/get-categories').then(function(response){
          return response.data
        }).catch(function(error){
          //pass
        })

        this.categories = res 
    
      }
    },

    created(){
      this.list_categories()
      window.scrollTo(0,0)
    }
  }


</script>

