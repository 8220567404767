<template>
	
	<div style="text-align: center; background-color: #f1f1f1; padding-top: 30px; padding-bottom: 5px;">
		

		<div>
			<img src="/assets/images/logo2.png" class="logo">
		</div>


		<div>
			<router-link to="/" class="btn color-1">Home</router-link>
			<router-link to="/all-menu" class="btn color-1">Menu</router-link>
			<router-link to="/how-it-works" class="btn color-1">How It Works</router-link>
			<router-link to="/contact" class="btn color-1">Contact Us</router-link>
			<router-link to="/faqs" class="btn color-1">FAQ</router-link>
		</div>

		<div>
			<h6>Eat Clean Achara</h6>
			<h6 class="color-3">Eat Clean Achara © 2024. All Rights Reserved</h6>
		</div>



	</div>


</template>