<template>

	<div class="navcontainer"> 
			<nav class="nav"> 
				<div class="nav-upper-options"> 
					
					<router-link to="/dashboard" class="nav-option"> 
						<i class="bi bi-speedometer nav-img color-3"></i>
						Dashboard 
					</router-link> 


				<div class="dropdown nav-option">
				  <div class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
				    <i class="bi bi-grid nav-img color-3"></i> Product categories
				  </div>
				  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
				    <li><router-link to="/new-product-category" class="dropdown-item">Add new</router-link></li>
				    <li><router-link to="/view-product-categories" class="dropdown-item">View categories</router-link></li>
				    
				  </ul>
				</div>


				<div class="dropdown nav-option">
				  <div class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
				    <i class="bi bi-layers nav-img color-3"></i> Products
				  </div>
				  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
				    <li><router-link to="/new-product" class="dropdown-item">Add new</router-link></li>
				    <li><router-link to="/view-products" class="dropdown-item">View products</router-link></li>
				    
				  </ul>
				</div>




					<div class="dropdown nav-option">
				  <div class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
				    <i class="bi bi-collection nav-img color-3"></i> Packages
				  </div>
				  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
				    <li><router-link to="/new-package" class="dropdown-item">Add new</router-link></li>
				    <li><router-link to="/view-packages" class="dropdown-item">View packages</router-link></li>
				    
				  </ul>
				</div>



				<div class="dropdown nav-option">
				  <div class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
				    <i class="bi bi-truck nav-img color-3"></i> Delivery locations
				  </div>
				  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
				    <li><router-link to="/new-location" class="dropdown-item">Add new</router-link></li>
				    <li><router-link to="/view-locations" class="dropdown-item">View locations</router-link></li>
				    
				  </ul>
				</div>


				<div class="dropdown nav-option">
				  <div class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
				    <i class="bi bi-people nav-img color-3"></i> Riders
				  </div>
				  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
				    <li><router-link to="/new-rider" class="dropdown-item">Add new</router-link></li>
				    <li><router-link to="/view-riders" class="dropdown-item">View riders</router-link></li>
				    
				  </ul>
				</div>


				<router-link to="/orders" class="nav-option"> 
						<i class="bi bi-list nav-img color-3"></i>
						Orders 
					</router-link> 			



				<router-link to="/settings" class="nav-option"> 
						<i class="bi bi-gear nav-img color-3"></i>
						Settings
					</router-link>


					<button class="nav-option btn" @click="logout()"> 
						<i class="bi bi-power nav-img color-3"></i>
						Log out
					</button>


					 




					

				</div> 
			</nav> 
		</div> 

  
</template>


<script>
	export default{
		name : 'side-bar',
		methods : {
			logout(){
				localStorage.clear()
				this.$router.push('/sudo')
			}	
		},

		created(){
			if(localStorage.getItem('admin_logged_in')!='true'){
				this.$router.push('/sudo')
			}	
		}
	}
</script>