<template>

	<Header />
	
	<section style="padding-top:100px;">
		

		<div class="container">


			<div class="row">
				<div class="col-md-2"></div>
				<div class="col-md-8">
					
					<div v-if="orders.length>0">
						<h6>Welcome,{{this.$store.state.rider_name}}</h6>
					</div>


					<div class="table-responsive">

						<table class="table table-hover table-striped">

							<tbody>
								
								<tr v-for="o in orders">
									<td>Order# : {{o.order_number}} </td>
									<td>Delivery location : {{o.location_name}} </td>
									<td> Phone : {{o.phone}} </td>

									<td> <p v-if="o.status=='arrived'" class="text-success"> <i class="bi bi-check"></i> Delivered</p> <button v-if="o.status!='arrived'" class="btn btn-2" @click="change_order_status(o.order_number,o.email,o.phone,o.first_name+' '+o.last_name,'arrived')" :disabled="disabled || o.status=='arrived' ">{{text}}</button> </td>
									

								</tr>

							</tbody>
							
						</table>


						<p class="text-danger" v-if="orders.length==0">No orders available.Try to login again if you have orders</p>
						
					</div>


					<div class="mt-3">

						<button class="btn btn-danger" @click="logout()"> <i class="bi bi-power"></i> Log Out</button>
						
					</div>


				</div>

			</div>

			
		</div>

	</section>


	<Footer2 />

</template>



<script>
	import Header from './layouts/Header'
	import Footer2 from './layouts/Footer2'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name  : 'login',
		components : {Footer2,Header},
		data(){
			return{
				orders : [],
				text : 'Arrive',
				disabled : false
			}
		},
		methods : {


			async change_order_status(order_number,email,phone,name,status){
				this.text = 'Please wait...'
				this.disabled = true
				const res = await axios.post(this.$store.state.end_point+'api/change-order-status',{
					order_number : order_number,
					email : email,
					phone : phone,
					name : name,
					status : status
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.rider_orders()

			if(res=='success'){
				Swal.fire({
				  title: 'Success',
				  text: 'Changes saved',
				  icon: 'success',
				  confirmButtonText: 'Close'
				})
			}else{
				Swal.fire({
				  title: 'Error!',
				  text: 'Something went wrong',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
			}

			this.text = 'Arrive'
			this.disabled = false

			},

			logout(){
				this.$router.push('/riders')
			},

			async rider_orders(){
				const res = await axios.get(this.$store.state.end_point+'api/rider-orders/'+this.$store.state.rider_pin).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.orders = res

			}

		},

		created(){
			this.rider_orders()
		}

	}

</script>

