<template>


<TopBar />

	<div class="main-container">
	<Sidebar /> 

		<div class="main"> 
		

		<div class="body-card shadow p-2 rounded">

			<div class="row">
			<div class="col-md-6">
				<h4>Products</h4>
			</div>

			<div class="col-md-6" style="text-align:right;">
				<router-link to="/new-product" class="btn btn-1"> <i class="bi bi-plus"></i> Add new</router-link>
			</div>
				
			</div>



			<div class="table-responsive">

			<table class="table table-hover table-striped">
			<thead>
				<tr> <th>Image</th> <th>Name</th> <th>Category</th> <th>Type</th> <th>Price</th> <th>Description</th> <th>Action</th> </tr>
			</thead>

			<tbody>
				
				<tr v-for="p in products">
					<td> <img :src="this.$store.state.end_point+'assets/product-images/'+p.image" style="width: 100px;" class="rounded"> </td>
					<td> {{p.name}} </td>
					<td> {{p.category}} </td>
					<td> {{p.product_type}} </td>
					<td> {{p.price}} </td>
					<td> <div v-html="p.description"></div> </td>
					<td>
						<div class="dropdown">
  <button class="btn btn-4 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Manage
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><router-link :to="{ name : 'edit-product', params : {product_number:p.product_number} }" class="dropdown-item">Edit</router-link></li>
    <li><button @click="delete_product(p.id)" class="dropdown-item">Delete</button></li>
  </ul>
</div>
					</td>
				</tr>

			</tbody>

			</table>	
				
			</div>
			
		</div>


		</div> 


		


	</div> 
	
</template>



<script>
	import Sidebar from './layouts/Sidebar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import Swal from 'sweetalert2'



	export default{
		name : 'dashboard',
		components : {Sidebar,TopBar},

		data(){
			return{
			products : []	
		}},

		methods : {

			async delete_product(id){
				const res = await axios.post(this.$store.state.end_point+'api/delete-product',{
					id : id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				this.list_products()
		
			},


			async list_products(){
				const res = await axios.get(this.$store.state.end_point+'api/list-products').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				this.products = res 
		
			}
			
		},

		created(){
			this.list_products()
		}
	}
</script>

