<template>


<TopBar />

	<div class="main-container">
	<Sidebar /> 

		<div class="main"> 
		

		<div class="body-card shadow p-2 rounded">

			<div class="row">
			<div class="col-md-6">
				<h4>Edit Product</h4>
			</div>


			<div class="col-md-6" style="text-align:right;">
				<router-link to="/view-products" class="btn btn-1"> <i class="bi bi-grid"></i> View products</router-link>
			</div>
				
			</div>



			<div>

				<form @submit.prevent="edit_product()" enctype="multipart/form-data">
					
					<div class="row">

						<div class="col-md-6 mt-2">
							<label>Product category</label>
							<select class="form-select" v-model="category">
								<option v-for="c in categories" :value="c.id"> {{c.name}} </option>
							</select>
						</div>

						<div class="col-md-6 mt-2">
							<label>Product name</label>
							<input type="text" class="form-control" placeholder="Enter product name" v-model="name">
						</div>

						<div class="col-md-6 mt-2">
							<label>Image</label>
							<input @change="get_image" type="file" class="form-control" placeholder="Image">
						</div>


						<div class="col-md-6 mt-2">
							<label>Type of product</label>
							<select class="form-select" v-model="product_type">
								<option value="single">Single</option>
								<option value="variable">Variable</option>
							</select>
						</div>

						<div class="col-md-12 mt-2" v-if="product_type=='variable' ">
							<label>Product sizes</label>
							
							<table class="table">
								<thead>
									<tr> <th>Name</th> <th>Price</th> <th>Action</th> </tr>
								</thead>
								<tbody>
								<tr v-for="s in sizes">
									<td> <i class="bi bi-check color-1"></i> {{s.size}} </td>
									<td> {{s.price}} </td>
									<td>
										<button @click="delete_size(s)" class="btn btn-danger"> <i class="bi bi-x"></i>Delete </button>
									</td>
								</tr>	
								</tbody>
							</table>
							
						</div>

						<div class="col-md-4 mt-2" v-if="product_type=='variable'">
							<label>Size</label>
							<input type="text"  class="form-control" placeholder="Enter size" v-model="size">
						</div>

						<div class="col-md-4 mt-2" v-if="product_type=='variable'">
							<label>Price</label>
							<input type="number" min="1"  class="form-control" placeholder="Enter price" v-model="size_price">
						</div>

						<div class="col-md-4 mt-2" style="padding-top:22px;" v-if="product_type=='variable'">

							<button @click="get_size()" type="button" class="btn btn-1"> <i class="bi bi-plus"></i> </button>
						</div>


						<div class="col-md-12 mt-2" v-if="product_type!='variable'">
							<label>Price</label>
							<input type="number" min="1" class="form-control" placeholder="Enter price" v-model="price">
						</div>


						<div class="col-md-12 mt-2">
							
							<label>Description</label>
						<ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
							
						</div>


						<div class="col-md-12 mt-2">
							
							<label>Ingredients</label>
						<ckeditor :editor="editor" v-model="ingredients" :config="editorConfig"></ckeditor>
							
						</div>


						<div class="col-md-12 mt-2">
							
							<label>Nutrition facts</label>
						<ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
							
						</div>


						<div class="col-md-12 mt-2">
							
							<label>Heating instructions</label>
						<ckeditor :editor="editor" v-model="heating_instructions" :config="editorConfig"></ckeditor>
							
						</div>


						<div class="col-md-12 mt-2">
							<button class="btn btn-1">Submit</button>
						</div>

					</div>

				</form>
				
			</div>
			
		</div>


		</div> 


		


	</div> 
	
</template>



<script>
	import Sidebar from './layouts/Sidebar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import CKEditor from '@ckeditor/ckeditor5-vue'
	import ClassicEditor from '@ckeditor/ckeditor5-build-classic'




	export default{
		name : 'dashboard',
		components : {Sidebar,TopBar,ckeditor: CKEditor.component},

		data(){
			return{
			product_number : '',
			sizes : [],
			size : '',
			size_price : '',
			name : '',
			image : '',
			description : '<p></p>',
			category : '',
			product_type : '',
			price : 0,
			ingredients : '<p></p>',
			heating_instructions : '<p></p>',
			editor: ClassicEditor,
			categories : [],
        	editorData: '',
        	editorConfig: {
            // The configuration of the editor.
        }

		}
	},

		methods : {

			delete_size(s){
				this.sizes.pop(s)
			},

			get_size(){

				if(!this.size){
					Swal.fire({
				  title: 'Error!',
				  text: 'Size  is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}

				if(!this.size_price){
					Swal.fire({
				  title: 'Error!',
				  text: 'Price  is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}

				let item = { 'size' : this.size, 'price' : this.size_price }
				this.sizes = this.sizes.concat(item)
				this.size = ''
				this.size_price = ''

			},

			async list_categories(){
				const res = await axios.get(this.$store.state.end_point+'api/list-categories').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				this.categories = res 
		
			},

			async product_details(){
				const res = await axios.get(this.$store.state.end_point+'api/product-details/'+this.product_number).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				res.forEach((data)=>{
					this.category = data.category
					this.name = data.name
					this.product_type = data.product_type
					this.price = data.price 
					this.description = data.description
					this.ingredients = data.ingredients
					this.editorData = data.nutrition
					this.heating_instructions = data.heating_instructions

				})

				if(this.product_type=='variable'){
					this.get_product_sizes()
				}
		
			},


			async get_product_sizes(){
				const res = await axios.get(this.$store.state.end_point+'api/product-sizes/'+this.product_number).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})

				this.sizes = res
		
			},

			get_image(e){
				this.image = e.target.files[0]  
			},

			async edit_product(){

				if(!this.category){
					Swal.fire({
				  title: 'Error!',
				  text: 'Category  is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}

				if(!this.name){
					Swal.fire({
				  title: 'Error!',
				  text: 'Product name is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}



				if(!this.product_type){
					Swal.fire({
				  title: 'Error!',
				  text: 'Product type is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}


				

				const config = {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }

				const res = await axios.post(this.$store.state.end_point+'api/edit-product',{
					name : this.name,
					image : this.image,
					description : this.description,
					category : this.category,
					product_type : this.product_type,
					price : this.price,
					ingredients : this.ingredients,
					nutrition : this.editorData,
					sizes : this.sizes,
					product_number : this.product_number,
					heating_instructions : this.heating_instructions
				},config).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
				
				if(res=='success'){
					Swal.fire({
				  title: 'Success',
				  text: 'Changes saved successfully',
				  icon: 'success',
				  confirmButtonText: 'Close'
				})
				this.product_details()
				}else{

					Swal.fire({
				  title: 'Error!',
				  text: res,
				  icon: 'error',
				  confirmButtonText: 'Close'
				})

				}


			}
			
		},

		created(){
			this.product_number = this.$route.params.product_number
			this.product_details()
			this.list_categories()	
		}
	}
</script>

