<template>


<TopBar />

	<div class="main-container">
	<Sidebar /> 

		<div class="main"> 
		

		<div class="body-card shadow p-2 rounded">

			<div class="row">
			<div class="col-md-6">
				<h4>New Rider</h4>
			</div>


			<div class="col-md-6" style="text-align:right;">
				<router-link to="/view-riders" class="btn btn-1"> <i class="bi bi-people"></i> View riders</router-link>
			</div>
				
			</div>



			<div>

				<form @submit.prevent="new_rider()" enctype="multipart/form-data">
					
					<div class="row">
						<div class="col-md-6 mt-2">
							<label>First name</label>
							<input type="text" class="form-control" placeholder="Enter first name" v-model="first_name">
						</div>

						<div class="col-md-6 mt-2">
							<label>Last name</label>
							<input type="text" class="form-control" placeholder="Enter last name" v-model="last_name">
						</div>

						<div class="col-md-6 mt-2">
							<label>Email</label>
							<input type="email" class="form-control" placeholder="Enter email address" v-model="email">
						</div>


						<div class="col-md-6 mt-2">
							<label>Phone</label>
							<input type="text" class="form-control" placeholder="Enter phone number" v-model="phone">
						</div>



						<div class="col-md-12 mt-2">
							<button class="btn btn-1" :disabled="disabled">{{text}}</button>
						</div>

					</div>

				</form>
				
			</div>
			
		</div>


		</div> 


		


	</div> 
	
</template>



<script>
	import Sidebar from './layouts/Sidebar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import Swal from 'sweetalert2'



	export default{
		name : 'dashboard',
		components : {Sidebar,TopBar},

		data(){
			return{
			first_name : '',
			last_name : '',
			email : '',
			phone : '',
			text : 'Submit',
			disabled : false	
		}},

		methods : {

			async new_rider(){

				if(!this.first_name){
					Swal.fire({
				  title: 'Error!',
				  text: 'First name is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}


				if(!this.last_name){
					Swal.fire({
				  title: 'Error!',
				  text: 'Last name is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}


				if(!this.phone){
					Swal.fire({
				  title: 'Error!',
				  text: 'Phone is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}

				if(!this.email){
					Swal.fire({
				  title: 'Error!',
				  text: 'Email is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}


				this.text = 'Please wait...'
				this.disabled = true

				

				const res = await axios.post(this.$store.state.end_point+'api/new-rider',{
					first_name : this.first_name,
					last_name : this.last_name,
					email : this.email,
					phone : this.phone
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
				
				if(res=='success'){
					Swal.fire({
				  title: 'Success',
				  text: 'Rider added successfully',
				  icon: 'success',
				  confirmButtonText: 'Close'
				})
					this.first_name = ''
					this.last_name = ''
					this.email = ''
					this.phone = ''
				}else{

					Swal.fire({
				  title: 'Error!',
				  text: res,
				  icon: 'error',
				  confirmButtonText: 'Close'
				})

				}

				this.text = 'Submit'
				this.disabled = false


			}
			
		},

		created(){
			
		}
	}
</script>

