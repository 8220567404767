<template>
<Header />

<section>
	
	<div class="container" style="padding-top: 50px; padding-bottom: 50px;">

		<div>
			<h3 class="color-3">Checkout</h3>
		</div>



		<div class="row">


			<div class="col-md-8">

				<div>
					
				<div>
					<h4>Billing details</h4>
				</div>


				<div>

					<div class="row">

						<div class="col-md-6 mt-3 col-6">
							<label>First name</label>
							<input type="text" v-model="first_name" class="form-control" placeholder="Enter your first name">
						</div>

						<div class="col-md-6 mt-3 col-6">
							<label>Last name</label>
							<input type="text" v-model="last_name" class="form-control" placeholder="Enter your last name">
						</div>

						<div class="col-md-6 mt-3 col-6">
							<label>Street address</label>
							<input type="text" v-model="address" class="form-control" placeholder="Enter your street address">
						</div>

						<div class="col-md-6 mt-3 col-6">
							<label>Postcode/ZIP</label>
							<input type="text" v-model="zip" class="form-control" placeholder="Enter your postal code">
						</div>

						<div class="col-md-6 mt-3 col-6">
							<label>Apartment</label>
							<input type="text" v-model="apartment" placeholder="Apartment,unit,suite,etc" class="form-control">
						</div>

						<div class="col-md-6 mt-3 col-6">
							<label>Town/City</label>
							<input type="text" v-model="town" class="form-control" placeholder="Enter your town of residence">
						</div>

						<div class="col-md-6 mt-3">
							<label>Phone</label>
							<input type="text" v-model="phone" class="form-control" placeholder="Enter your phone number">
						</div>

						<div class="col-md-6 mt-3">
							<label>Email</label>
							<input type="email" v-model="email" class="form-control" placeholder="Enter your email address">
						</div>

						

					</div>

				</div>

				</div>
				
			</div>


			<div class="col-md-4 mt-3">
				
				<div class="shadow rounded p-2" style="background-color:#fff;">
					<h4>Delivery location <i class="bi bi-geo-alt color-1"></i> </h4>

					<label>Choose your delivery location below</label>

					<select class="form-select" v-model="location" @change="get_delivery_fee()">
						<option  v-for="l in locations" :value="l.id"> {{l.name}} </option>
					</select>


					<div class="mt-3">
						<h5 class="text-danger">Delivery fee : Ksh.{{delivery_fee}}</h5>
						<h5>Tota cost : Ksh.{{this.$store.state.final_total_cost}}</h5>
						<h5><b>Total amount(With delivery fee) : Ksh.{{this.$store.state.amount_to_pay}}</b></h5>
					</div>

				</div>




				<div class="shadow rounded mt-3 p-2" style="background-color:#fff;">

					<h4>Payment options</h4>
					<h5 class="color-3"> <i class="bi bi-check-circle color-1"></i> MPESA/CARD PAYMENT </h5>
					<img src="/assets/images/pesapal-share.png" style="width:150px;">


					<div class="mt-3">
						<button class="btn btn-1" @click="place_order()" :disabled="disabled"> {{text}} </button>
					</div>
					
					
				</div>



			</div>

			
		</div>

		
	</div>


</section>









<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import Swal from 'sweetalert2'
	import axios from 'axios'
	import 'animate.css';
	export default{
		name : 'index',
		components : {Header,Footer},
		data(){
			return{
				locations : [],
				location : '',
				delivery_fee : 0,
				first_name : '',
				last_name  : '',
				email : '',
				phone : '',
				address : '',
				zip : '',
				apartment : '',
				town : '',
				location_name : '',
				link : '',
				order_number : '',
				text : 'Place order',
				disabled : false
			}
		},

		methods : {

			async pay_balance(){
				//window.open(this.link, "Pay your order", 'width=800,height=600');
				//window.close();
				window.location.href=this.link
			},

			async place_order(){

				if(!this.first_name){
					Swal.fire({
				  title: 'Error!',
				  text: 'First name is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}


				if(!this.last_name){
					Swal.fire({
				  title: 'Error!',
				  text: 'Last name is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}

				if(!this.email){
					Swal.fire({
				  title: 'Error!',
				  text: 'Email is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}

				if(!this.phone){
					Swal.fire({
				  title: 'Error!',
				  text: 'Phone is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}


				if(!this.address){
					Swal.fire({
				  title: 'Error!',
				  text: 'Address is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}


				if(!this.town){
					Swal.fire({
				  title: 'Error!',
				  text: 'Town is required!',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}


				if(!this.location){
					Swal.fire({
				  title: 'Error!',
				  text: 'Please enter your delivery location',
				  icon: 'error',
				  confirmButtonText: 'Close'
				})
					return
				}

				this.order_number = Math.floor(Math.random() * 10000) + 100
				
				this.text = "Please wait..."
				this.disabled = true

				const res = await axios.post(this.$store.state.end_point+'api/place-order',{
					first_name : this.first_name,
					last_name : this.last_name,
					email : this.email,
					phone : this.phone,
					address : this.address,
					zip : this.zip,
					town : this.town,
					apartment : this.apartment,
					location_name : this.location_name,
					delivery_fee : this.delivery_fee,
					total_cost : this.$store.state.final_total_cost,
					amount_to_pay : this.$store.state.amount_to_pay,
					cart : this.$store.state.cart,
					order_number : this.order_number
					
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
				
				if(res=='success'){
					Swal.fire({
				  title: 'Success',
				  text: 'Order submitted successfully',
				  icon: 'success',
				  confirmButtonText: 'Close'
				})

				this.link = this.$store.state.end_point+'pesapal-pay/'+this.order_number+'/'+this.first_name+'/'+this.last_name+'/'+this.email+'/'+this.phone+'/'+this.$store.state.amount_to_pay
				this.pay_balance()

				this.$store.state.cart = []
				this.$store.state.is_package_selected = 'no'
				this.$store.state.total_qty_final= 0
				this.$router.push('/')
					
				}else{

					Swal.fire({
				  title: 'Error!',
				  text: res,
				  icon: 'error',
				  confirmButtonText: 'Close'
				})

				}

				this.text = "Place order"
				this.disabled = false

			},

			get_delivery_fee(){
				
				this.locations.forEach((data)=>{
					if(this.location==data.id){
						this.delivery_fee = data.price
						this.location_name = data.name 
						this.$store.state.delivery_fee = data.price
						this.$store.state.amount_to_pay = parseInt(this.$store.state.final_total_cost) + parseInt(data.price)
					}
				})

			},

			async get_locations(){
				
				const res = await axios.get(this.$store.state.end_point+'api/get-locations').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
				
				this.locations = res
				

			},
		},

		created(){
			this.get_locations()
			this.$store.state.amount_to_pay = parseInt(this.$store.state.final_total_cost) + this.delivery_fee
		}
	}

</script>