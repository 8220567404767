<template>


<TopBar />

	<div class="main-container">
	<Sidebar /> 

		<div class="main"> 
		

		<div class="body-card shadow p-2 rounded">

			<div class="row">
			<div class="col-md-6">
				<h4>Packages</h4>
			</div>


			<div class="col-md-6" style="text-align:right;">
				<router-link to="/new-package" class="btn btn-1"> <i class="bi bi-plus"></i> New package</router-link>
			</div>
				
			</div>



			<div class="table-responsive">

				<table class="table">

					<thead>
						<tr>
							<th>Name</th> <th> Quantity </th> <th>Discount(%)</th> <th>Action</th>
						</tr>
					</thead>

					<tbody>
					
					<tr v-for="p in packages">
						
						<td> {{p.name}} </td>
						<td> {{p.qty}} </td>
						<td> {{p.discount}} </td>
						<td>
							<div class="dropdown">
  <button class="btn btn-4 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Manage
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><router-link :to="{ name : 'edit-package', params : { id : p.id } }" class="dropdown-item">Edit</router-link></li>
    <li><button @click="delete_package(p.id)" class="dropdown-item">Delete</button></li>
  </ul>
</div>
						</td>

					</tr>

					</tbody>
					
				</table>
				
			</div>
			
		</div>


		</div> 


		


	</div> 
	
</template>



<script>
	import Sidebar from './layouts/Sidebar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import Swal from 'sweetalert2'



	export default{
		name : 'dashboard',
		components : {Sidebar,TopBar},

		data(){
			return{
			packages : [],	
		}},

		methods : {

			async delete_package(id){
				const res = await axios.post(this.$store.state.end_point+'api/delete-package',{
					id : id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.get_packages()

			},

			async get_packages(){
				const res = await axios.get(this.$store.state.end_point+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					console.log(error)
				})
			this.packages = res

			}
			
		},

		created(){
		this.get_packages()	
		}
	}
</script>

